import "./dishes-section.css";

const DisheSection = () => {
  return (

    <div className="dishes-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-3">
          <div class="card card-1">
            <div class="card-body dihes-card-body">
              <img
                className="food-section-img"
                src="./images/ellipse-8@2x.png"
                alt=""
              />
            </div>
          </div>
          <div className="card-title-1">Starter</div>
        </div>
        <div class="col-md-6 col-lg-3">
          {/* <!-- Column 2 content --> */}
          <div class="card card-1">
            <div class="card-body dihes-card-body">
              <img
                className="food-section-img"
                src="./images/ellipse-9@2x.png"
                alt=""
              />
            </div>
          </div>
          <div className="card-title-1">Dinner</div>
        </div>
        <div class="col-md-6 col-lg-3">
          {/* <!-- Column 3 content --> */}
          <div class="card card-1">
            <div class="card-body dihes-card-body">
              <img
                className="food-section-img"
                src="./images/ellipse-10@2x.png"
                alt=""
              />
            </div>
          </div>
          <div className="card-title-1">Lunch</div>
        </div>
        <div class="col-md-6 col-lg-3">
          {/* <!-- Column 4 content --> */}
          <div class="card card-1">
            <div class="card-body dihes-card-body">
              <img
                className="food-section-img"
                src="./images/ellipse-11@2x.png"
                alt=""
              />
            </div>
          </div>
          <div className="card-title-1">Dessert</div>
        </div>
      </div>
    </div>
    </div>
  );
};
export default DisheSection;
