import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Navbar from '../Navbar/Navbar';

function Cancel() {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        navigate('/restaurants');
    };

    useEffect(() => {
        // This effect runs once when the component mounts
        setShow(true);
    }, []);

    return (
        <div>
            <Navbar />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Transaction Failed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Transaction Failed</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Go to Restaurants
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Cancel;
