// // import React, { useState } from "react";
// // import "./faq-section.css";

// // const AccordionItem = ({ title, content, isOpen, onClick, index, isLast }) => (
// //     <div className="accordion-item">
// //         <div className="accordion-title" onClick={onClick}>
// //             <span className="accordion-title-text"><span className="accordion-index">{index + 1 < 10 ? `0${index + 1}` : index + 1}</span> {title}</span>
// //             <span>{isOpen ? "×" : "+"}</span>
// //         </div>
// //         {isOpen && <div className="accordion-content">{content}</div>}
// //         {!isLast && <hr />}
// //     </div>
// // );

// // const FaqSection = () => {
// //     const [openIndex, setOpenIndex] = useState(null);

// //     const toggleAccordion = (index) => {
// //         setOpenIndex(openIndex === index ? null : index);
// //     };

// //     const accordionData = [
// //         {
// //             title: "WHAT ABOUT OTHER LOCAL LAWS AND REGULATIONS?",
// //             content:
// //                 "Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle.",
// //         },
// //         {
// //             title: "HOW DOES THE ORDER PROCESS WORK?",
// //             content: "Content for order process work.",
// //         },
// //         {
// //             title: "CAN SHARED KITCHEN OWNER JOIN OUR PLATFORM?",
// //             content: "Content for shared kitchen owner joining.",
// //         },
// //         {
// //             title: "HOW QUICKLY CAN RESTAURANT DISHES BE ADDED TO THE PLATFORM?",
// //             content: "Content for adding restaurant dishes quickly.",
// //         },
// //         {
// //             title: "HOW DOES THE ORDER PROCESS WORK?",
// //             content: "Content for order process work.",
// //         },
// //     ];

// //     return (
// //         <div className="faq-section-main">
// //             <div>
// //                 <h1>Frequently Asked Questions</h1>
// //                 <p>Contact Us For More Info</p>
// //             </div>
// //             <div className="faq-accordion">
// //                 {accordionData.map((item, index) => (
// //                     <AccordionItem
// //                         key={index}
// //                         title={item.title}
// //                         content={item.content}
// //                         isOpen={openIndex === index}
// //                         onClick={() => toggleAccordion(index)}
// //                         index={index}
// //                         isLast={index === accordionData.length - 1}
// //                     />
// //                 ))}
// //             </div>
// //         </div>
// //     );
// // };

// // export default FaqSection;














// import React, { useState } from "react";
// import "./faq-section.css";

// // const AccordionItem = ({ title, content, isOpen, onClick, index, isLast }) => (
// //     <div className="accordion-item">
// //         <div className="accordion-title" onClick={onClick}>
// //             <span className="accordion-title-text">
// //                 <span className="accordion-index">
// //                     {index + 1 < 10 ? `0${index + 1}` : index + 1}
// //                 </span>{" "}
// //                 {title}
// //             </span>
// //             <span>{isOpen ? "×" : "+"}</span>
// //         </div>
// //         {isOpen && <div className="accordion-content">{content}</div>}
// //         {!isLast && <hr className="hrtag" />}
// //     </div>
// // );

// const FaqSection = () => {
//     // const [openIndex, setOpenIndex] = useState(null);

//     // const toggleAccordion = (index) => {
//     //     setOpenIndex(openIndex === index ? null : index);
//     // };

//     // const accordionData = [
//     //     {
//     //         title: "What service are we offering?",
//     //         content:
//     //             "ForunPoint is an online platform that provides food and gift services. We offer a wide selection of delicious meals and unique gifts, conveniently delivered to your doorstep. Whether you’re looking to enjoy a great meal at home or send a special gift to a loved one, ForunPoint has you covered.",
//     //     },
//     //     {
//     //         title: "How easy is it to order dishes from my favorite restaurant?",
//     //         content: "Ordering dishes from your favorite restaurant is incredibly easy with ForunPoint. We have hundreds of restaurants within your area, and you can use our advanced search capability to quickly find your favorite restaurant and browse their menu. With just a few clicks, you can place your order and enjoy a delicious meal delivered right to your doorstep.",
//     //     },
//     //     {
//     //         title: "What is the cancellation policy?",
//     //         content: "Users can cancel their order via the platform before it is accepted by the restaurant. Once the order is in progress, cancellation is not possible.",
//     //     },
//     //     {
//     //         title: "Can we order food or gifts through the website?",
//     //         content: "Yes, you can order food or gifts using the ForunPoint app, as well as the website.",
//     //     },
//     // ];

//     return (
//         <div className="faq-section-main">
//             <h2 className="test-title">Frequently Asked Questions</h2>
//             {/* 
//             <div className="faq-heading">
//                 <h1 className="faq-title">Frequently<br />Asked Questions</h1>
//                 <p className="faq-title-2">Contact Us For More Info</p>
//             </div>
//             <div className="faq-accordion">
//                 {accordionData.map((item, index) => (
//                     <AccordionItem
//                         key={index}
//                         title={item.title}
//                         content={item.content}
//                         isOpen={openIndex === index}
//                         onClick={() => toggleAccordion(index)}
//                         index={index}
//                         isLast={index === accordionData.length - 1}
//                     />
//                 ))}
//             </div> */}
//         </div>
//     );
// };

// export default FaqSection;



// src/FAQ.js
import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './faq-section.css';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const faqs = [
        { question: "What service are we offering?", answer: "ForunPoint is an online platform that provides food and gift services. We offer a wide selection of delicious meals and unique gifts, conveniently delivered to your doorstep. Whether you’re looking to enjoy a great meal at home or send a special gift to a loved one, ForunPoint has you covered." },
        { question: "How easy is it to order dishes from my favorite restaurant?", answer: "Ordering dishes from your favorite restaurant is incredibly easy with ForunPoint. We have hundreds of restaurants within your area, and you can use our advanced search capability to quickly find your favorite restaurant and browse their menu. With just a few clicks, you can place your order and enjoy a delicious meal delivered right to your doorstep." },
        { question: "What is the cancellation policy?", answer: "Users can cancel their order via the platform before it is accepted by the restaurant. Once the order is in progress, cancellation is not possible." },
        { question: "Can we order food or gifts through the website?", answer: "Yes, you can order food or gifts using the ForunPoint app, as well as the website." },
    ];

    const toggleAccordion = index => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <div className="faq-heading">
                <h1>Frequently<br />asked question</h1>
                <p>CONTACT US FOR MORE INFO</p>
            </div>
            <Accordion className="faq-accordion">
                {faqs.map((faq, index) => (
                    <AccordionItem key={index}>
                        <AccordionItemHeading onClick={() => toggleAccordion(index)}>
                            <AccordionItemButton>
                                <p className='faq-question'><span className="faq-index">{index + 1}</span>{faq.question}</p>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {openIndex === index && (
                                <div className="accordion-content">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default FAQ;
