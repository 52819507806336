import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { IoIosStar } from "react-icons/io";
import { ConvertPKRtoUSD } from '../../App';
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import '../dish-detail-popup/Dish-popup.css';

const DetailsPopup = ({ isOpen, onClose, dishDetails, addToCart, isTaxPayable }) => {
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState({});
    const [crust, setCrust] = useState('');
    const [flavour, setFlavour] = useState('');
    const [sauce, setSauce] = useState('');
    const [total, setTotal] = useState(0);
    const [addontotal, setAddonTotal] = useState(0);
    const [validationMessage, setValidationMessage] = useState('');
    const storedCountry = localStorage.getItem('country');

    let [addons, setAddons] = useState([]);

    useEffect(() => {
        if (storedCountry === 'US') {
            if (dishDetails?.priceUSD) {
                dishDetails.price = dishDetails.priceUSD;
            }
            if (dishDetails?.sizes) {
                dishDetails.sizes = dishDetails.sizes.map(size => ({
                    ...size,
                    price: size.priceUSD || size.price // Fallback to PKR price if USD price is not available
                }));
            }
        }  else  if (storedCountry === 'GB') {
            if (dishDetails?.priceGBP) {
                dishDetails.price = dishDetails.priceGBP;
            }
            if (dishDetails?.sizes) {
                dishDetails.sizes = dishDetails.sizes.map(size => ({
                    ...size,
                    price: size.priceGBP || size.price // Fallback to PKR price if USD price is not available
                }));
            }
        }
        else  if (storedCountry === 'AU') {
            if (dishDetails?.priceAUD) {
                dishDetails.price = dishDetails.priceAUD;
            }
            if (dishDetails?.sizes) {
                dishDetails.sizes = dishDetails.sizes.map(size => ({
                    ...size,
                    price: size.priceAUD || size.price // Fallback to PKR price if USD price is not available
                }));
            }
        }   else  if (storedCountry === 'AE') {
            if (dishDetails?.priceAED) {
                dishDetails.price = dishDetails.priceAED;
            }
            if (dishDetails?.sizes) {
                dishDetails.sizes = dishDetails.sizes.map(size => ({
                    ...size,
                    price: size.priceAED || size.price // Fallback to PKR price if USD price is not available
                }));
            }
        }   else  if (storedCountry === 'SA') {
            if (dishDetails?.priceSAR) {
                dishDetails.price = dishDetails.priceSAR;
            }
            if (dishDetails?.sizes) {
                dishDetails.sizes = dishDetails.sizes.map(size => ({
                    ...size,
                    price: size.priceSAR || size.price // Fallback to PKR price if USD price is not available
                }));
            }
        }
        getTotalPrice();
    }, [storedCountry, dishDetails, quantity, selectedSize, addontotal]);
    const handleAddonSelect = (addon, selected) => {
        if (selected) {
            setAddons([...addons, addon]);
            setAddonTotal((prevTotal) => prevTotal + addon.addonPrice);
        } else {
            setAddons(addons.filter((item) => item._id !== addon._id));
            setAddonTotal((prevTotal) => prevTotal - addon.addonPrice);
        }
    };
    

    const getTotalPrice = () => {
        let price = 0;
        if (dishDetails?.price) {
            price = dishDetails.price * quantity;
        } else if (selectedSize?.price) {
            price = selectedSize.price * quantity;
        }
        else if (dishDetails?.sizes && dishDetails.sizes.length > 0) {
            const defaultSize = dishDetails.sizes[0];
            setSelectedSize(defaultSize);
            price = defaultSize.price * quantity;
        }
        setTotal(price + addontotal);
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
        getTotalPrice();
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
        getTotalPrice();
    };

    // const getAddons = (addon, selected) => {
    //     if (selected) {
    //         addon.selected = selected;
    //         setAddons((prevArray) => [...prevArray, addon]);
    //         setAddonTotal((prevTotal) => prevTotal + addon.addonPrice);
    //     } else if (!selected) {
    //         addon.selected = selected;
    //         setAddons(addons.filter((item) => {
    //             if (item._id === addon._id && addontotal > 0) {
    //                 setAddonTotal((prevTotal) => prevTotal - item.addonPrice);
    //             }
    //             return item._id !== addon._id;
    //         }));
    //     }
    // };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<IoIosStar key={i} className='star-ico' />);
            } else {
                stars.push(<IoIosStar key={i} className='star-ico' id='black-ico' />);
            }
        }
        return stars;
    };

    const handleAddToCart = () => {
        if (!selectedSize?.price && dishDetails?.sizes && dishDetails.sizes.length > 0) {
            setValidationMessage('Please select a size.');
            setTimeout(() => {
                setValidationMessage('');
            }, 4000);
            return;
        }

        let cartItems = {
            dish: dishDetails,
            selectedAddons: addons,
            quantity: quantity,
            size: selectedSize,
            crust: crust,
            flavour: flavour,
            sauce: sauce
        };

        
        if (addToCart && typeof addToCart === 'function') {
            if (dishDetails.sizes.length > 0) {
                addToCart({
                    cartItems: cartItems,
                    id: dishDetails._id,
                    name: dishDetails.dishName,
                    quantity: quantity,
                    price: selectedSize.price,
                    image: dishDetails.picture,
                    isTaxPayable: isTaxPayable,
                });
                dishDetails.price = selectedSize.price;
            } else {
                addToCart({
                    cartItems: cartItems,
                    id: dishDetails._id,
                    name: dishDetails.dishName,
                    quantity: quantity,
                    price: dishDetails.price,
                    image: dishDetails.picture,
                    isTaxPayable: isTaxPayable,
                });
            }
            closeModel();
        }
        // console.log(addToCart())
    };
    // const handleAddToCart = () => {
    //     if (!selectedSize?.price && dishDetails?.sizes && dishDetails.sizes.length > 0) {
    //         setValidationMessage('Please select a size.');
    //         setTimeout(() => {
    //             setValidationMessage('');
    //         }, 4000);
    //         return;
    //     }

    //     let cartItems = {
    //         dish: dishDetails,
    //         selectedAddons: addons,
    //         quantity: quantity,
    //         size: selectedSize,  // Pass the selected size here
    //         crust: crust,
    //         flavour: flavour,
    //         sauce: sauce
    //     };

    //     if (addToCart && typeof addToCart === 'function') {
    //         // Add size to the cart item to ensure unique size handling
    //         addToCart({
    //             cartItems: cartItems,
    //             id: dishDetails._id,
    //             name: dishDetails.dishName,
    //             quantity: quantity,
    //             price: selectedSize.price || dishDetails.price, // Handle price based on size or dish price
    //             image: dishDetails.picture,
    //             isTaxPayable: isTaxPayable,
    //         });
            
    //     }
    //     closeModel();
    // };


    const setSize = (size) => {
        setSelectedSize(size);
        setValidationMessage(''); // Clear validation message when a size is selected
    };

    const closeModel = () => {
        onClose();
        setTotal(0);
        setQuantity(1);
        setSelectedSize({});
        setCrust('');
        setFlavour('');
        setSauce('');
        setAddonTotal(0);
        setAddons([]);
        setValidationMessage(''); // Clear validation message on close
    };

    if (!isOpen || !dishDetails) {
        return null;
    }

    return (
        <div>
            <Modal className='details-modal' open={isOpen} onClose={closeModel} center>
                <div>
                    <div className='modal-header'>
                        <img src={dishDetails?.picture} className='dish-image' alt='dish-img' />
                    </div>
                    <div className="modal-body">
                        <h2 className='dish-name'>{dishDetails.dishName}</h2>
                        {dishDetails?.price ? (
                            <p className='price-home'>
                                {ConvertPKRtoUSD(dishDetails?.price)}
                            </p>
                        ) : (
                            dishDetails?.sizes?.[0]?.price && (
                                <p className='price-home'>
                                    {ConvertPKRtoUSD(dishDetails?.sizes[0]?.price)}
                                </p>
                            )
                        )}
                    </div>
                    {dishDetails.description !== '' && <div className="modal-details">
                        <p className="modal-description">
                            <b>Description: </b>
                            <p>{dishDetails?.description}</p>
                        </p>
                    </div>}
                    <div className="restaurant-details-sec">
                        <p className='details-title'>{dishDetails?.user?.restaurantName}</p>
                        <p className="ratings">({dishDetails?.user?.rating})
                            {renderStars(dishDetails?.user?.rating)}
                        </p>
                    </div>
                    <div className="modal-sec">
                        {dishDetails.sizes.length > 0 && <div className="modal-addons">
                            <p className='addons-title'>Sizes</p>
                            {dishDetails.sizes.map((size) => (
                                <div className='addons-list' key={size._id}>
                                    <div className="radio-sec">
                                        <input type="radio" name='size' onChange={() => setSize(size)} className='radio-btn' />
                                        <label className='addon-name'>{size.size}</label>
                                    </div>
                                    <p className='addon-price'>{ConvertPKRtoUSD(size.price)}</p>
                                </div>
                            ))}
                            {validationMessage && <p className="validation-message">{validationMessage}</p>}
                        </div>}
                        {/* Addons, flavours, sauces, and crust sections remain unchanged */}
                        {/* {dishDetails.addons?.length > 0 && <div className="modal-addons">
                            <p className='addons-title'>Addons</p>
                            {dishDetails.addons.map((addon) => (
                                <div className='addons-list' key={addon._id}>
                                    <div className="checkbox-sec">
                                        <input
                                            type="checkbox"
                                            name='addon'
                                            onChange={(e) => handleAddonSelect(addon, e.target.checked)}
                                            className='radio-btns'
                                        />
                                        <label className='addon-names'>{addon.addonName}</label>
                                    </div>
                                    <p className='addon-price'>{ConvertPKRtoUSD(addon.addonPrice)}</p>
                                </div>
                            ))}
                        </div>} */}
                    </div>
                </div>
                <div className="bottom-bar">
                    <div className="quantity-handler">
                        <span onClick={decreaseQuantity}><LuMinusCircle /></span>
                        <p>{quantity}</p>
                        <span onClick={increaseQuantity}><LuPlusCircle /></span>
                    </div>
                    <div>
                        <button className="add-to-cart-btn" onClick={handleAddToCart}>Add To Cart</button>
                    </div>
                    <div>
                        <button className="view-cart-btn">View Cart</button>
                    </div>
                    <div>
                        <button className="price-btn">{ConvertPKRtoUSD(total?.toFixed(2))}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DetailsPopup;
