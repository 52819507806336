import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Increment the counter here
    // This could be a call to your backend API to increment the visit count

    // Redirect to the Instagram page
    window.location.href = 'https://www.instagram.com/forunpoint.pk';
  }, [navigate]);

  return (
    <div>Redirecting you to Instagram...</div>
  );
};

export default RedirectPage;
