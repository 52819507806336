import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./career.css";

const Careers = () => {
    return (
        <div>
            <Navbar />
            <div className="privacy-container">
                <img className="career-img" src="./images/career-cover.png" alt="Career Cover" />
            </div>
            <div className="privacy mt-5 mb-5">
                <div className="career-title">Current openings</div>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Job Type</th>
                            <th scope="col">Location</th>
                            <th scope="col">Status</th>
                            <th scope="col">Details</th>
                            <th scope="col">Apply</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" data-label="Title">Food Delivery Driver</th>
                            <td data-label="Job Type">Part-Time/Full-Time</td>
                            <td data-label="Location">Lahore, Punjab, Pakistan</td>
                            <th scope="row" data-label="Status" style={{ color: 'green' }}>Open</th>
                            <td data-label="Details"><button style={{ color: '#d4110f', textDecoration: 'none', fontWeight: '700' }} type="button" className="slastd btn btn-linkr" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Details</button></td>
                            <td data-label="Apply" className="lastd"><a style={{ color: '#d4110f', textDecoration: 'none' }} href="/driver">Apply Now</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Job details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            As a rider, you'll be responsible for timely and efficient delivery of food orders to our valued customers, ensuring they receive their meals hot and fresh.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Careers;
