import "./food-section.css";

const foodsection = () => {
  return (
    <div className="welcome-section" id="food-section">
      <div className="welcome-heading-section">
        <h2 className="welcome-heading">Vision</h2>
        <h1 className="main-welcome-heading">Welcome To ForunPoint</h1>
      </div>
      <div className="welcome-text">
      At ForunPoint, we envision ourselves to be amongst the top online food and gift delivery services in Pakistan. We aim to provide an exquisite food catalogue, prompt deliveries, and top-notch services. Our Customer-Centric approach pushes us to strive for customer satisfaction and is one of our core values.
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="card food-section-card">
              <div class="card-body">
                <div className="food-label-sec">
                  <p className="food-card-label">Restaurant</p>
                </div>
                <img
                  className="food-section-img-1"
                  id="rest-image"
                  src="./images/restaurant01-1@2x.png"
                  alt="Restaurants"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            {/* <!-- Column 2 content --> */}
            <div class="card food-section-card">
              <div class="card-body">
                <div className="food-label-sec">
                  <p className="food-card-label">Flowers</p>
                </div>
                <img
                  className="food-section-img-3"
                  id="flowers-card-img"
                  src="./images/profesional-chef01-1@2x.png"
                  alt="Flowers"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            {/* <!-- Column 3 content --> */}
            <div class="card food-section-card">
              <div class="card-body">
              <div className="food-label-sec">
                  <p className="food-card-label">Gifts</p>
                </div>
                <img
                  className="food-section-img-2"
                  src="./images/shared-kitchen01-1@2x.png"
                  alt="Gifts"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            {/* <!-- Column 4 content --> */}
            <div class="card food-section-card">
              <div class="card-body">
              <div className="food-label-sec">
                  <p className="food-card-label">Riders</p>
                </div>
                <img
                  className="food-section-img-4"
                  id="riders-image"
                  src="./images/food-safety01-1@2x.png"
                  alt="Riders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default foodsection;
