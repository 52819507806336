import "./delivery-section.css";

const DeliverySection = () => {
  return (
    <div class="delivery-container">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="delivery-card">
            <div class="card-body">
              <ul className="delivery-list">
                <li className="delivery-list-heading"><span className="list-title">Indulge Your Cravings</span></li>
                <p className="delivery-content">
                  ForunPoint is your passport to a world of flavours! From creamy delights to fiery feasts, we've got your taste buds covered with our diverse cuisine options.
                </p>
                <li><span className="list-title">Spread the Love</span></li>
                <p className="delivery-content">
                  Surprise your loved ones with a tasty treat! With ForunPoint's gift-giving feature, you can easily send delicious meals to celebrate special moments or simply show your appreciation.
                </p>
                <li><span className="list-title">Voice Command</span></li>
                <p className="delivery-content">
                  Busy day? No problem! With ForunPoint's voice ordering, you can satisfy your hunger with just a few words. It's convenience at your command!
                </p>
                <li><span className="list-title">Search Made Simple</span></li>
                <p className="delivery-content">
                  Craving something specific? ForunPoint's optimised search results make it a breeze to find your favourite dishes. Just a few taps and you're on your way to culinary bliss!
                </p>
                <li><span className="list-title">Savour the Savings</span></li>
                <p className="delivery-content">
                  Enjoy more for less with ForunPoint's exclusive deals from top restaurants. Treat yourself to your favourite foods without breaking the bank!
                </p>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="delivery-card">
            <div class="card-body">
              <img
                className="delivery-section-img"
                src="./images/deliveryimg.png"
                alt="Delivery"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliverySection;
