// import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
// import "./joinus-section.css";
// import DatePicker from "react-datepicker";
// import { joinUs } from "../../shared/apis";
// import "react-datepicker/dist/react-datepicker.css";

// function JoinusSetion() {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phoneNumber: "",
//     address: "",
//     totalLocations: "",
//     contactDate: new Date(),
//     information: "",
//   });
//   const [isDeliveryCheck, setDeliveryCheck] = useState();
//   const [isInternet, setInternet] = useState();
//   const [error, setError] = useState("");
//   const [role, setRole] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const DeliveryCheck = (e) => {
//     if (e.target.value === "yes") {
//       setDeliveryCheck(true);
//     } else {
//       setDeliveryCheck(false);
//     }
//   };

//   const Internet = (e) => {
//     if (e.target.value === "yes") {
//       setInternet(true);
//     } else {
//       setInternet(false);
//     }
//   };
//   const handleDateChange = (date) => {
//     setFormData({ ...formData, contactDate: date });
//   };

//   const changeDropDown = (e) => {
//     setRole(e.target.value);
//   };

//   const submit = async () => {
//     let body = formData;
//     body.role = role;
//     if (!body.name) {
//       setError("Name is required.");
//     } else if (!body.email) {
//       setError("Email is required.");
//     } else if (!body.phoneNumber) {
//       setError("Phone number is required.");
//     } else if (!body.address) {
//       setError("Address is required.");
//     } else if (!body.role) {
//       setError("Please select a role.");
//     } else {
//       setError('');
//       body.deliverySetup = isDeliveryCheck;
//       body.Internet = isInternet;
//       await joinUs(body);
//       window.location.reload();
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="joinus-section">
//         <div className="joinus-form">
//           <h1 className="title">ForunPoint partners registration</h1>
//           <div className="para">
//             We kindly request you to complete the following form. Your responses
//             will enable us to understand your needs and preferences effectively.
//           </div>
//           <div className="form-container">
//             <form>
//               <label htmlFor="name">Name:</label>
//               <br />
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 placeholder="John Doe"
//               />
//               <br />
//               <label htmlFor="email">Email:</label>
//               <br />
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 placeholder="johndoe@mail.com"
//               />
//               <br />
//               <label htmlFor="phone">Phone:</label>
//               <br />
//               <input
//                 type="tel"
//                 id="phoneNumber"
//                 name="phoneNumber"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 placeholder="+100000000"
//               />
//               <br />
//               <label htmlFor="address">Address:</label>
//               <br />
//               <input
//                 type="text"
//                 id="address"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 placeholder="TX, USA"
//               />
//               <br />
//               <label htmlFor="role">
//                 Role (Example: Restaurant Owner/Professional Chef):
//               </label>
//               <br />
//               <select className="multi-select" onChange={changeDropDown}>
//               <option value="">Please select a role</option>
//                 <option value="RESTAURANT">Restaurant</option>
//                 <option value="PROFESSIONAL_CHEF">Professional Chef</option>
//               </select>
//               <br />
//               <label htmlFor="text">
//                 How many locations you have within Dallas Forth Worth?
//               </label>
//               <br />
//               <input
//                 type="text"
//                 id="totalLocations"
//                 name="totalLocations"
//                 value={formData.text}
//                 onChange={handleChange}
//               />
//               <br />
//               <label htmlFor="dateTime">
//                 What is the date and time to contact you?
//               </label>
//               <br />
//               <DatePicker
//                 selected={formData.contactDate}
//                 onChange={handleDateChange}
//                 showTimeSelect
//                 timeFormat="HH:mm"
//                 timeIntervals={15}
//                 timeCaption="Time"
//                 dateFormat="MMMM d, yyyy h:mm aa"
//                 placeholderText="Pick a Date and Time"
//               />
//               <br />
//               <label htmlFor="options">Do you have any delivery setup?</label>
//               <br />
//               <br />
//               <input
//                 type="radio"
//                 value="yes"
//                 name="deliverySetup"
//                 onChange={DeliveryCheck}
//               />

//               <label className="radio-label" htmlFor="options">
//                 Yes
//               </label>
//               <input
//                 type="radio"
//                 value="no"
//                 name="deliverySetup"
//                 onChange={DeliveryCheck}
//               />
//               <label className="radio-label" htmlFor="options">
//                 No
//               </label>
//               <br />
//               <br />
//               <label htmlFor="options">
//                 Do you have a computer and internet access in your restaurant?
//               </label>
//               <br />
//               <br />
//               <input
//                 type="radio"
//                 value="yes"
//                 name="internet"
//                 onChange={Internet}
//               />
//               <label className="radio-label" htmlFor="options">
//                 Yes
//               </label>
//               <input
//                 type="radio"
//                 value="no"
//                 name="internet"
//                 onChange={Internet}
//               />
//               <label className="radio-label" htmlFor="options">
//                 No
//               </label>
//               <br />
//               <br />

//               <label htmlFor="comment">
//                 Any additional information you would like to share?
//               </label>
//               <br />

//               <textarea
//                 id="information"
//                 name="information"
//                 value={formData.comment}
//                 onChange={handleChange}
//                 placeholder="Information"
//               />
//               <br />
//             </form>
//             <div class="center-container">
//               <button className="join-form-btn" onClick={() => submit()}>
//                 Submit
//               </button>
//             </div>
//             <div className="error-text">{error}</div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default JoinusSetion;

import React, { useState } from "react";
// import Navbar from "../../Navbar/Navbar";
// import Footer from "../../Footer/footer";
import "./joinus-section.css";
import DatePicker from "react-datepicker";
import { joinUs, uploadImageWithoutSize } from "../../shared/apis";
import "react-datepicker/dist/react-datepicker.css";
// import { sendMessage } from "../../shared/apis";
import Toast from "react-bootstrap/Toast";
function JoinusSetion() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    totalLocations: "",
    contactDate: new Date(),
    information: "",
    restaurantName: "",
    menu: ""
  });
  const [isDeliveryCheck, setDeliveryCheck] = useState();
  const [isInternet, setInternet] = useState();
  const [error, setError] = useState("");
  const [role, setRole] = useState("");
  let [State, setState] = useState({});
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [File, setFile] = React.useState();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [disable, setDisable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const DeliveryCheck = (e) => {
    if (e.target.value === "yes") {
      setDeliveryCheck(true);
    } else {
      setDeliveryCheck(false);
    }
  };

  const Internet = (e) => {
    if (e.target.value === "yes") {
      setInternet(true);
    } else {
      setInternet(false);
    }
  };
  const handleDateChange = (date) => {
    setFormData({ ...formData, contactDate: date });
  };

  const changeDropDown = (e) => {
    setRole(e.target.value);
  };

  const handleFile = async (event) => {
    setDisable(false);
    setMessage(false);
    // console.log(event);
    setFile(event.target.files[0]);
    // console.log(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(reader.result);
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const submit = async (e) => {
    let body = formData;
    body.role = role;
    // console.log(role)
    // console.log(body)

    // e.preventDefault();
    // setShow(true);
    // if (State.email) {
    //   await joinUs(State);
    //   setMessage(true);

    //   // Clear the form fields by resetting the State
    //   setState({});

    //   // Reload the page to reset the form fields
    //   // window.location.reload();
    // }


    if (!body.name) {
      setError("Name is required.");
    } else if (!body.restaurantName) {
      setError("Restaurant name is required.");
    } else if (!body.email) {
      setError("Email is required.");
    } else if (!body.phoneNumber) {
      setError("Phone number is required.");
    } else if (!body.address) {
      setError("Restaurant address is required.");
    } else if (!body.role) {
      setError("Please select a role.");
    } else if (!File) {
      setError("Please upload menu picture");
    } else {
      setError('');
      setDisable(true);
      if (File) {
        const formData = new FormData();
        formData.append("file", File);
        let res = await uploadImageWithoutSize(formData);
        body.menu = res.data.data.url;
      }
      body.deliverySetup = isDeliveryCheck;
      body.Internet = isInternet;
      await joinUs(body);
      // window.location.reload();

      e.preventDefault();
      setShow(true);
      if (State.email) {
        await joinUs(State);
        setMessage(true);

        // Clear the form fields by resetting the State
        setState({});

        // Reload the page to reset the form fields
        // window.location.reload();
      }
      window.location.href = '/';
      setDisable(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="joinus-section">
        <Toast onClose={() => setShow(false)} show={show} delay={4000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt="Joinus-toast"
            />
            <strong className="me-auto">ForunPoint</strong>
          </Toast.Header>
          <Toast.Body>Form Submitted!</Toast.Body>
        </Toast>
        <div className="joinus-form">
          <h1 className="title">ForunPoint partners registration</h1>
          <div className="para">
            We kindly request you to complete the following form. Your responses
            will enable us to understand your needs and preferences effectively.
          </div>
          <div className="para">
            ✅ Device Less Concept.<br></br>
            ✅ Expand Your Audience.<br></br>
            ✅ Boost Sales.<br></br>
            ✅ No Hidden Fees.<br></br>
            ✅ Dedicated Support.<br></br>
            ✅ Promotional Opportunities.<br></br>
            ✅ Live & Generate Revenue In 3 Minutes.<br></br>
          </div>
          <div className="form-container-join">
            <form>
              <label htmlFor="name">Name:</label>
              <br />
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="John Doe"
              />
              <br />
              <label htmlFor="restaurantName">Restaurant Name:</label>
              <br />
              <input
                type="text"
                id="restaurantName"
                name="restaurantName"
                value={formData.restaurantName}
                onChange={handleChange}
                placeholder="Restaurant Name"
              />
              <br />
              <label htmlFor="email">Email:</label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="johndoe@mail.com"
              />
              <br />
              <label htmlFor="phone">Phone:</label>
              <br />
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phone}
                onChange={handleChange}
                placeholder="+9230000000"
              />
              <br />
              <label htmlFor="address">Restaurant Address:</label>
              <br />
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="DHA, Lahore"
              />
              <br />
              <label htmlFor="role">
                Role (Example: Restaurant Owner/Professional Chef):
              </label>
              <br />
              <select className="multi-select" onChange={changeDropDown}>
                <option value="">Please select a role</option>
                <option value="RESTAURANT">Restaurant</option>
                <option value="PROFESSIONAL_CHEF">Professional Chef</option>
              </select>
              <br />
              <label htmlFor="text">
                How many locations you have within Lahore?
              </label>
              <br />
              <input
                type="text"
                id="totalLocations"
                name="totalLocations"
                value={formData.text}
                onChange={handleChange}
              />
              <br />
              <label htmlFor="dateTime">
                What is the date and time to contact you?
              </label>
              <br />
              <DatePicker
                selected={formData.contactDate}
                onChange={handleDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholderText="Pick a Date and Time"
              />
              <br />
              <label htmlFor="options">Do you have any delivery setup?</label>
              <br />
              <br />
              <input
                type="radio"
                value="yes"
                name="deliverySetup"
                onChange={DeliveryCheck}
              />

              <label className="radio-label" htmlFor="options">
                Yes
              </label>
              <input
                type="radio"
                value="no"
                name="deliverySetup"
                onChange={DeliveryCheck}
              />
              <label className="radio-label" htmlFor="options">
                No
              </label>
              <br />
              <br />
              <label htmlFor="options">
                Do you have internet access in your restaurant?
              </label>
              <br />
              <br />
              <input
                type="radio"
                value="yes"
                name="internet"
                onChange={Internet}
              />
              <label className="radio-label" htmlFor="options">
                Yes
              </label>
              <input
                type="radio"
                value="no"
                name="internet"
                onChange={Internet}
              />
              <label className="radio-label" htmlFor="options">
                No
              </label>
              <br />
              <br />

              <label htmlFor="comment">
                Any additional information you would like to share?
              </label>
              <br />

              <textarea
                id="information"
                name="information"
                value={formData.comment}
                onChange={handleChange}
                placeholder="Information"
              />
              <br />
              <div style={{ textAlign: "left", marginTop: "20px" }}>
              <label className="radio-label" htmlFor="comment">
                Upload restaurant menu here:
              <br /><input style={{ marginBottom: "15px", marginTop: "15px" }} type="file" onChange={handleFile} />
              </label>
                {uploadedImage && (
                  <img
                    src={uploadedImage}
                    alt="Uploaded"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </div>
            </form>
            <div class="center-container">
              {/* <button className="join-form-btn" onClick={() => submit()}> */}
              <button className="join-form-btn" onClick={(e) => submit(e)}
                disabled={message || disable}>
                Submit
              </button>
            </div>
            <div className="error-text">{error}</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JoinusSetion;
