import React, { useEffect, useState } from 'react';
import './landing-gif.css';

const LandingGif = () => {
  const [showGif, setShowGif] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowGif(false);
    }, 6000);

  }, []);

  if (!showGif) return null;

  return (
    <div className="gif-container">
      <img src="./images/Gif-1.gif" alt="Landing Animation" className="responsive-gif" />
    </div>
  );
};

export default LandingGif;
