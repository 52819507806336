import React from 'react';
import mobile1 from '../../../assets/images/mobile1.png';
import mobile2 from '../../../assets/images/mobile2.png';
import appstore from '../../../assets/images/appstore-btn.png';
import playstore from '../../../assets/images/playstore-btn.png';
import './features.css';

function features() {
    return (
        <div className='features-page'>
            <div className="features-content">
                <img src={mobile1} className='mobile-image-1' alt="Mobile Image 1" />
                <ul className='content-list'>
                    <li className='feature-heading'><span className='feature-heading-text'>ForunPoint App</span></li>
                    <p className='feature-desc'>Experience culinary delights with the ForunPoint app! From cheesy pizza to juicy burgers and spicy tenders, we offer fast, reliable deliveries. Enjoy features like gift giving, voice ordering, and easy search. Track your order in real-time and access exclusive deals from top restaurants. Download ForunPoint now for delicious food delivered straight to your door!</p>
                    <div className='store-btns-wrapper'>
                    <a
                      href="https://apps.apple.com/us/app/forunpoint/id1630196197"
                      target="_blank"
                      rel="noreferrer"
                    >
                        <img src={appstore} className='store-btns' />
                      
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user"
                      target="_blank"
                      rel="noreferrer"
                    >
                        <img src={playstore} className='store-btns' />
                      
                    </a>
                    </div>
                    <br /><br />
                    <li className='feature-heading'><span className='feature-heading-text'>ForunPoint Partners</span></li>
                    <p className='feature-desc'>ForunPoint Partner is your go-to tool for culinary success. Elevate your restaurant with our intuitive app—get live in just 3 minutes and manage orders, sales, and financial analytics in real-time. Enjoy features like real-time order tracking, customer reviews, and notification alerts. Register today and experience the ForunPoint difference. Your success is just a tap away!</p>
                    <div className='store-btns-wrapper'>
                    <a
                    href="https://apps.apple.com/us/app/d-vour-chef/id1541482615"
                    target="_blank"
                    rel="noreferrer"
                  > 
                        <img src={appstore} className='store-btns' />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.whetcloud.de_vour_chef"
                    target="_blank"
                    rel="noreferrer"
                  >
                   
                        <img src={playstore} className='store-btns' />
                  </a>
                    </div>
                    <br /><br />
                    <li className='feature-heading'><span className='feature-heading-text'>ForunPoint Rider</span></li>
                    <p className='feature-desc'>Join the ForunPoint delivery team and elevate your service experience! Our driver app ensures safety, security, and seamless communication. Enjoy flexible scheduling, recognition for your hard work, and access to better incentives, bonus earnings, performance tracking, and badge rewards. Install ForunPoint Rider now and start your journey as a rider!</p>
                    <div className='store-btns-wrapper'>
                    <a
                      href="https://apps.apple.com/us/app/forunpoint-driver/id1630196763"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appstore} className='store-btns' />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.driver"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={playstore} className='store-btns' />
                    </a>
                        
                    </div>
                </ul>
                <img src={mobile2} className='mobile-image-2' alt="Mobile Image 2" />
            </div>
        </div>
    )
}

export default features
