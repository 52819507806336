import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./contactus-section.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import { sendMessage } from "../../shared/apis";
import Toast from "react-bootstrap/Toast";
import { Helmet } from 'react-helmet';

const ContactUsSection = () => {
  let [State, setState] = useState({});
  const [message, setMessage] = useState(false);
  const [show, setShow] = useState(false);
  const submitMessage = async (e) => {
    e.preventDefault();
    setShow(true);
    if (State.email) {
      await sendMessage(State);
      setMessage(true);
      setState({});
      window.location.reload();
    }
  };

  const changeField = (value) => {
    const v = { ...State };
    v[value.field] = value.value;
    setState(v);
  };
  return (
    <div>
      <Helmet>
        <title>Contact Us | Get in Touch with ForunPoint</title>
      </Helmet>
      <Navbar />
      <div class="container contactus-section">
        <div class="row">
          <div class="col-md-6 col-lg-7">
            <div class="contactus-card">
              <div class="card-body">
                <h2 className="contactus-section-heading">Contact Us</h2>
                <img src="./images/about-underline.png" alt="Contact Us" className="underline" />
                &nbsp;
                <img
                  src="./images/about-underline-s.png"
                  alt="About"
                  className="underline"
                />
                &nbsp;
                <img
                  src="./images/about-underline-xs.png"
                  alt="About Image"
                  className="underline"
                />
                <p className="contactus-section-p">
                  <br /> At ForunPoint, our vision is to be among the top online
                  fresh food and gift delivery service in the Pakistan. The mission
                  is to have a great food selection, efficient and superior
                  service, customer satisfaction is our paramount objective.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="contactus-card">
              <div class="card-body">
                <img className="contactus-img" src="./images/contactus-1.png" alt="Contact Us" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-contact" id="contact">
        <Toast onClose={() => setShow(false)} show={show} delay={4000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt="Contact Us Message"
            />
            <strong className="me-auto">ForunPoint</strong>
          </Toast.Header>
          <Toast.Body>Message Sent!</Toast.Body>
        </Toast>
        <div className="text-center pt-5">
          <div className="contact-title">Contact Us</div>
          <div className="contact-subtitle">
            You can also get in touch with us using the contact form below.
          </div>
        </div>
        <Form className="pt-5 pb-5">
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label className="contact-form-label">Name</Form.Label>
              <Form.Control
                onChange={(e) =>
                  changeField({ field: "name", value: e.target.value })
                }
                type="text"
                placeholder="Name"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label className="contact-form-label">Email</Form.Label>
              <Form.Control
                onChange={(e) =>
                  changeField({ field: "email", value: e.target.value })
                }
                type="email"
                placeholder="Email"
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="contact-form-label">Message</Form.Label>
            <Form.Control
              onChange={(e) =>
                changeField({ field: "message", value: e.target.value })
              }
              as="textarea"
              rows={6}
              placeholder="Message"
            />
          </Form.Group>
          <div className="submit-btn-div-message">
            <Button
              onClick={(e) => submitMessage(e)}
              disabled={message}
              className="btn-submit-msg"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUsSection;
