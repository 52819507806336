import Navbar from "../Navbar/Navbar";
import CookieConsent from "../cookies-consent/cookies-consent";
import "./err404.css";
const error404 = () => {
  return (
    <>
    <Navbar/>
    <div className="error-container">
    <img src="../images/err404.png" alt="Your Image" />
    <h2 className="main-heading" >Something went wrong</h2>
    <h6>We're sorry, but something went wrong. Please try to refresh the page.</h6>
  </div>
  {/* <CookieConsent/> */}
    </>
  );
};

export default error404;
