import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slider.css";

const SimpleSlider = () => {
  return (
    <Carousel
      showArrows={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
      showThumbs={false}
      showStatus={false}
      transitionTime={500}
    >
      <a href="/secondpage">
        <div className="slider-images">
          <img src="./images/Gift.jpg" className="images" />
        </div>
      </a>
      <a href="/firstpage">
        <div className="slider-images">
          <img src="./images/Voice.jpg" className="images" />
        </div>
      </a>
      <a href="/thirdpage">
        <div className="slider-images">
          <img src="./images/third.jpeg" className="images" />
        </div>
      </a>
    </Carousel>
  );
};

export default SimpleSlider;
