
import http from "./http";
import FPHTTP from './constants/fp';
// import setupProxy from "../setupProxy";

export const sendMessage = (body) => {
  return http.post("api/sendMessage", body).then((res) => res);
};

export const joinUs = (body) => {
  return http.post("api/v1/users/form/driver", body).then((res) => res);
};

//this api user profile returns
export const getMe = () => {
  return http.get('api/v1/users/profile').then(res => res.data);
};

export const login = (body) => {
  return http.post('api/v1/users/login', body).then(res => res.data);
};

export const getOrdersList = (offset, limit, orderStatus) => {
  return http.get(`api/v1/orders?limit=${limit}&offset=${offset}&orderStatus=${orderStatus}&role=USER`).then(res => res.data);
};
export const getGiftOrdersList = (limit, offset, orderStatus, id) => {
  return http.get(`api/v1/giftOrder?limit=${limit}&offset=${offset}&orderStatus=${orderStatus}&_id=${id}&role=USER`).then(res => res.data);
};
//IN_PROGRESS for active order

export const getKitchensList = () => {
  return http.get(`/api/v1/kitchens`).then(res => res.data);
};

export const getAllDishes = (id) => {
  return http.get(`api/v1/dishes/?role=CHEF&_id=${id}`).then(res => res.data);
};

export const deleteUser = (id) => {
  return http.delete(`api/v1/users/${id}`).then(res => res.data);
};

export const deleteOrder = (id) => {
  return http.delete(`api/v1/orders/${id}`).then(res => res.data);
};

export const deleteDish = (id) => {
  return http.delete(`api/v1/dishes/${id}`).then(res => res.data);
};

export const deleteKitchen = (id) => {
  return http.delete(`api/v1/kitchens/${id}`).then(res => res.data);
};

export const getProfitDetails = () => {
  return http.get(`/api/v1/financials`).then(res => res.data);
};

export const getDietary = () => {
  return http.get(`/api/v1/dietaries`).then(res => res.data);
};

export const getCategory = () => {
  return http.get(`/api/v1/categories`).then(res => res.data);
};

export const getCuisine = () => {
  return http.get(`/api/v1/cuisines`).then(res => res.data);
};

export const uploadImage = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/s3",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const uploadImageWithoutSize = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/withoutsize/s3",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};
export const uploadVideo = async (file) => {
  const response = await http({
    method: "post",
    url: "/api/upload-video",
    data: file,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const addDish = (body) => {
  return http.post(`/api/v1/dishes`, body).then(res => res.data);
};

export const updateUser = (id, body) => {
  return http.put(`api/v1/users/${id}`, body).then(res => res.data);
};
//id and body which one is changed

export const updateOrder = (id, body) => {
  return http.put(`api/v1/orders/${id}`, body).then(res => res.data);
};

export const getSingleOrder = (id) => {
  return http.get(`api/v1/orders/${id}`).then(res => res.data);
};
export const getSticker = () => {
  return http.get(`api/v1/stickers`).then(res => res.data);
};

export const connectStripe = (body) => {
  return http.post(`/api/v1/dishes`, body).then(res => res.data);
};

export const createCoupon = (body) => {
  return http.post(`/api/v1/payments/stripe/coupon`, body).then(res => res.data);
};

export const getCoupon = (code) => {
  return http.get(`/api/v1/payments/stripe/coupon/${code}`).then(res => res.data);
};

export const deleteCoupon = (code) => {
  return http.delete(`/api/v1/payments/stripe/coupon/delete/${code}`).then(res => res.data);
};

export const updateDish = (id, body) => {
  return http.put(`api/v1/dishes/${id}`, body).then(res => res.data);
};

export const getDishById = (id, body) => {
  return http.get(`api/v1/dishes/${id}`, body).then(res => res.data);
};

export const signup = (body) => {
  return http.post('api/v1/users/signup', body).then(res => res.data);
};

export const forgotPassword = (body) => {
  return http.post(`api/v1/users/forgot-password`, body).then(res => res.data);
};

export const changePassword = (body) => {
  return http.post(`api/v1/users/change-password`, body).then(res => res.data);
};

export const updatePassword = (body) => {
  return http.post(`api/v1/users/update-password`, body).then(res => res.data);
};
//have to send oldPassword,password 

export const loginWithGoogle = (body) => {
  return http.post(`api/v1/users/loginsocial/google`, body).then(res => res.data);
};

export const getPopular = (id) => {
  return http.get(`api/v1/orders/popular/byChef/${id}`).then(res => res.data);
};

export const autoComplete = (input, token) => {
  return http.get(`api/v1/google/autocomplete/${input}/${token}`).then(res => res.data);
};

export const placeDetail = (id, token) => {
  return http.get(`api/v1/google/placedetails/${id}/${token}`).then(res => res.data);
};

export const verifyEmail = (body) => {
  return http.post(`api/v1/users/verify-email`, body).then(res => res.data);
};

export const verificationEmail = (body) => {
  return http.post(`api/v1/users/verification-email`, body).then(res => res.data);
};

export const getLast24HoursOrders = (id) => {
  return http.get(`api/v1/orders/last24hours/${id}`).then(res => res.data);
};

export const nearByResturants = (role, lat, long, offset, limit, radius, requestRole, _id) => {
  return http.get(`api/v1/users?role=${role}&lat=${lat}&lng=${long}&requestRole=${requestRole}&offset=${offset}&limit=${limit}&radius=${radius}`).then(res => res.data);
};
// export const giftandFlowers = () => {
//   return http.get(`api/v1/gifts`).then(res => res.data);
// };
export const getgiftByid = (id) => {
  return http.get(`api/v1/giftorder/${id}`).then(res => res.data);
};
export const giftandFlowers = (city) => {
  return http.get(`api/v1/gifts?city=${city}`).then(res => res.data);
};
export const topRestaurants = (lat, long, radius) => {
  return http.get(`api/v1/users/top-restaurants/${lat}/${long}/?radius=${radius}`).then(res => res.data);
};

export const featureDeals = (lat, long) => {
  return http.get(`api/v1/dishes/deals/offers?lat=${lat}&lng=${long}`).then(res => res.data);
};

export const autoCompleteSearch = (body) => {
  return http.post(`api/v1/search/autocomplete`, body).then(res => res.data);
};

export const keyworkSearch = (body) => {
  return http.post(`api/v1/search`, body).then(res => res.data);
};

export const sendVerificationEmail = (body) => {
  return http.post(`api/v1/users/send-verification-email`, body).then(res => res.data);
};

export const createCustomer = (body) => {
  return http.post(`api/v1/payments/customer/create`, body).then(res => res.data);
};

export const updateCustomer = (body) => {
  return http.post(`api/v1/payments/customer/update`, body).then(res => res.data);
};

export const getCustomer = (customerId) => {
  return http.get(`api/v1/payments/customer/${customerId}`).then(res => res.data);
};

export const createPaymentMethod = (body) => {
  return http.post(`api/v1/payments/payment-method/create`, body).then(res => res.data);
};

export const updatePaymentMethod = (pmId, body) => {
  return http.post(`api/v1/payments/payment-method/update/${pmId}`, body).then(res => res.data);
};

export const getpaymentMethod = (pmId) => {
  return http.get(`api/v1/payments/payment-method/${pmId}`).then(res => res.data);
};

export const attachPaymentMethodToCustomer = (body) => {
  return http.post(`api/v1/payments/payment-method/attach`, body).then(res => res.data);
};

export const createOrder = (body) => {
  return http.post(`api/v1/orders/`, body).then(res => res.data);
};
export const createGiftOrder = (body) => {
  return http.post(`api/v1/giftorder/`, body).then(res => res.data);
};

export const createCheckout = (body) => {
  return http.post(`api/v1/payments/checkout`, body).then(res => res.data);
};
export const detachPaymentMethod = (pmId) => {
  return http.post(`api/v1/payments/payment-method/detach/${pmId}`).then(res => res.data);
};

export const getCouponDetails = (Id) => {
  return http.get(`api/v1/payments/coupons/${Id}`).then(res => res.data);
};


//////////////////////////////////////////////////////////////////////////////
//ForunPoint Api's

export const createTrip = (body) => {
  return FPHTTP.post(`/trips/create`, body).then(res => res.data);
};
// export const jazzcash = (body) => {
//   return setupProxy.post(`/api/CustomerPortal/transactionmanagement/merchantform/`, body).then(res => res);
// };


export const getCityRestaurants = (city, limit, offset) => {
  return http.get(`api/v1/users/city-name-restaurants?city=${city}&limit=${limit}&offset=${offset}`)
    .then(res => res.data);
};







// 1. Get Balance
export const getBalance = (userId) => {
  return http.get(`api/v1/wallet/balance/${userId}`, {
    // headers: {
    //   'Cookie': 'connect.sid=s%3Ayfw6lqfpc6GQI3Eztpv557b_eB1fxsqq.LGWNswSf0SpjfQlqkhOY5WeEjVCnv6b75MOeBeoKdfg'
    // }
  }).then(res => res.data);
};

// 2. Make Transaction
export const makeTransaction = (userId, amount) => {
  return http.post('api/v1/wallet/payment', {
    userId,
    amount
  }, {
    // headers: {
    //   'Content-Type': 'application/json',
    //   'Cookie': 'connect.sid=s%3AGpRCS0nM2yc4MsE0vUgrz_XGO-bnth04.iFjSlc%2FEzwfwhsmVPqI%2Fw2dwXaVnxHgKcCGnddo%2Brq0'
    // }
  }).then(res => res.data);
};

// 3. Deposit
export const deposit = (userId, amount) => {
  return http.post('api/v1/wallet/deposit', {
    userId,
    amount
  }, {
    // headers: {
    //   'Content-Type': 'application/json',
    //   'Cookie': 'connect.sid=s%3AiLqHU32D1Pm5Qd3EHjDG4S0EZfNMMnXi.NuDPT3rd2vZTfQTLhXZnBzxV8ZUECPxE%2BNhatf6EG7g'
    // }
  }).then(res => res.data);
};

// Get Directions
export const getDirections = (currentLat, currentLng, destinationLat, destinationLng) => {
  return http.post('api/v1/locations/directions', {
    currentLat,
    currentLng,
    destinationLat,
    destinationLng
  }, {
    // headers: {
    //   'Content-Type': 'application/json',
    //   'Cookie': 'connect.sid=s%3AyHcR8TgUPY9yGc68Yh-Lc2s9ivN0BcEM.HPjOTHeGzBTvntrQx7AmGvD%2FiYeL27SfB2%2BDrGJtqjE'
    // }
  }).then(res => res.data);
};
