import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./forunpoint-solution.css";
const DvourSolution = () => {
  return (
    <div>
      <Navbar />
      <div className="dvou-solution">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-5">
              <div class="dvour-solution-card">
                <div class="card-body">
                  <h2 className="dvour-solution-card1">
                    ForunPoint's Solution
                  </h2>
                  <img
                    src="./images/about-underline.png"
                    alt="About-solution"
                    className="underline"
                  />
                  &nbsp;
                  <img
                    src="./images/about-underline-s.png"
                    alt="About-solution"
                    className="underline"
                  />
                  &nbsp;
                  <img
                    src="./images/about-underline-xs.png"
                    alt="About-solution"
                    className="underline"
                  />
                  <br />
                  <br />
                  <p>
                    In today’s fast-paced and digital-centric world, users,
                    restaurants and drivers face unique challenges. Addressing
                    these pain points is essential for survival and growth in
                    the modern food and culinary stry.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-7">
              <div class="dvour-solution-card">
                <div class="card-body">
                  <img
                    className="dvour-solutionimg"
                    src="./images/networkimg.png"
                    alt="About-solution"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-12">
              <div class="dvour-solution-card">
                <div class="card-body">
                  <p>
                    <br />
                    To create the best offer for the three key entities involved
                    in the online food ordering and delivery ecosystem – users,
                    restaurants, and drivers – it’s important for ForunPoint to
                    tailor the benefits to each group’s unique needs and
                    preferences:
                    <br />
                    <br />
                    <span className="solution-pera">1. Users: </span>
                    <ul>
                      <li>
                        Discounts and Loyalty Rewards: Offer first-time user
                        discounts, loyalty points for repeated orders, and
                        special deals during holidays or events.
                      </li>
                      <li>
                        Diverse and Quality Food Options: Ensure a wide variety
                        of restaurant choices, including healthy, vegetarian,
                        and international cuisines.
                      </li>
                      <li>
                        User-Friendly App Experience: Provide an
                        easy-to-navigate app interface, quick checkout process,
                        and real-time order tracking.
                      </li>
                    </ul>
                    <span className="solution-pera">2. Restaurants: </span>
                    <ul>
                      <li>
                        Lower Commission Rates: Offer competitive commission
                        rates, enabling more profits for the restaurants.
                      </li>
                      <li>
                        Marketing and Visibility Support: Provide promotional
                        features on the platform, highlighting new or popular
                        restaurants to attract more customers.
                      </li>
                      <li>
                        Business Insights and Analytics: Offer data analytics
                        tools to help restaurants understand customer
                        preferences, peak ordering times, and popular menu
                        items.
                      </li>
                    </ul>
                    <span className="solution-pera">3. Drivers: </span>
                    <ul>
                      <li>
                        Competitive Compensation and Flexibility: Offer fair
                        pay, including bonuses for peak times, and allow drivers
                        to choose their working hours for greater flexibility.
                      </li>
                      <li>
                        Incentives for Performance: Implement a reward system
                        for timely and efficient deliveries, and positive
                        customer feedback.
                      </li>
                      <li>
                        Support and Safety Measures: Provide a support system
                        for drivers, including safety guidelines, insurance
                        options, and an efficient grievance redressal mechanism.
                      </li>
                    </ul>
                    <br />
                    Each of these offers is designed to address the specific
                    requirements and improve the overall experience of users,
                    restaurants, and drivers, creating a more efficient and
                    satisfying online food ordering and delivery service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DvourSolution;
