import React from 'react';
import { HiArrowLongLeft } from "react-icons/hi2";
import { HiArrowLongRight } from "react-icons/hi2";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './updates.css';

const CustomPrevArrow = (props) => (
  <div {...props} className="custom-arrow2 prev-arrow2">
      <HiArrowLongLeft />
  </div>
);

const CustomNextArrow = (props) => (
  <div {...props} className="custom-arrow2 next-arrow2">
      <HiArrowLongRight />
  </div>
);

function Updates() {
  const settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  return (
    <div className='updatesec'>
    <div className='updates-section'>
      <h3 className="updates-heading my-5 text-center">Our Updates</h3>
      <Slider {...settings} className="carousel slider-updates">
        <div className="card-background">
          <div className="update-card">
            <a href='/blog'>
              <div className="card-body">
                <img
                  className="updates-section-img"
                  src="./images/Gift.jpg"
                  alt="Gifts"
                />
              </div>
            </a>
            <div className="update-card-title">
              <a className='update-link' href='/blog'>ForunPoint Spreading Joy, One Gift at a Time</a>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className="update-card">
            <a href='/blog'>
              <div className="card-body">
                <img
                  className="updates-section-img"
                  src="./images/Voice.jpg"
                  alt="Voice"
                />
              </div>
            </a>
            <div className="update-card-title">
              <a className='update-link' href='/blog'>ForunPoint’s Solution with Voice Command Food Ordering</a>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className="update-card">
            <a href='/blog'>
              <div className="card-body">
                <img
                  className="updates-section-img"
                  src="./images/third.jpeg"
                  alt="Blogs"
                />
              </div>
            </a>
            <div className="update-card-title">
              <a className='update-link' href='/blog'>ForunPoint's Blueprint for Success in 2024</a>
            </div>
          </div>
        </div>
        {/* <div className="card-background">
          <div className="update-card">
            <a href='/blog'>
              <div className="card-body">
                <img
                  className="updates-section-img"
                  src="./images/third.jpeg"
                  alt=""
                />
              </div>
            </a>
            <div className="update-card-title">
              <a className='update-link' href='/blog'>ForunPoint's Blueprint for Success in 2024</a>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className="update-card">
            <a href='/blog'>
              <div className="card-body">
                <img
                  className="updates-section-img"
                  src="./images/third.jpeg"
                  alt=""
                />
              </div>
            </a>
            <div className="update-card-title">
              <a className='update-link' href='/blog'>ForunPoint's Blueprint for Success in 2024</a>
            </div>
          </div>
        </div>
        <div className="card-background">
          <div className="update-card">
            <a href='/blog'>
              <div className="card-body">
                <img
                  className="updates-section-img"
                  src="./images/third.jpeg"
                  alt=""
                />
              </div>
            </a>
            <div className="update-card-title">
              <a className='update-link' href='/blog'>ForunPoint's Blueprint for Success in 2024</a>
            </div>
          </div>
        </div> */}
      </Slider>
    </div>
  </div>
  );
}

export default Updates;
