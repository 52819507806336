import React from 'react'
import './firstpage.css'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/footer'

function firstpage() {
    return (
        <div>
            <Navbar />


            <div className="firstpage">
                <div className="first-page-img">
                    <img src="./images/Voice.jpg" alt="Voice" />
                </div>
                <div className="first-page-title">
                    <h1>ForunPoint’s Solution with Voice Command Food Ordering</h1>
                </div>
                <div className="first-page-content">
                    <p>

                        In today's fast-paced world, convenience is key. At ForunPoint, we're constantly innovating to make the food ordering experience seamless and effortless for our customers. One of our latest and most exciting features is our Voice Command feature, revolutionising the way you order food online. This article includes the following headings:
                        <br />
                        <br />
                        <ul>
                            <li>Effortless Ordering, Hands-Free <br />
                            </li>
                            <li>How it works <br />
                            </li>
                            <li>Convenience Redefined <br />
                            </li>
                            <li>The Future of Food Ordering <br />
                            </li>

                        </ul>
                    </p>
                    <h2>Effortless Ordering, Hands-Free</h2>
                    <p>

                        Picture this: You're deep into a Netflix binge, on the edge of your seat with the climax approaching, when suddenly, hunger strikes. Instead of hitting pause and ruining the suspense, why not let your voice take charge? With ForunPoint's Voice Command feature, it's as easy as speaking your order into your device. Say goodbye to interruptions and hello to seamless, hands-free ordering – all at the sound of your voice. Welcome to the future of food ordering, where simplicity reigns supreme and typing and tapping are a thing of the past.
                    </p>

                    <h2>How It Works</h2>
                    <p>


                        Using ForunPoint's Voice Command feature is easy. Simply open the ForunPoint app on your smartphone or smart device and activate the voice command function. Then, speak naturally to your device, specifying your desired restaurant, cuisine, and dishes. Our advanced voice recognition technology will process your request and generate a list of options tailored to your preferences. Once you've made your selections, confirm your order with a simple voice command, and voila – your delicious meal is on its way.


                    </p>


                    <h2>Convenience Redefined</h2>
                    <p>

                        Our Voice Command feature isn't just about convenience – it's about redefining the way you order food online. Whether you're juggling a hectic schedule, dealing with mobility issues, or simply prefer a hands-free approach, ForunPoint's Voice Command feature puts the power of ordering at your fingertips – or rather, at the sound of your voice.

                    </p>
                    <h2>The Future of Food Ordering</h2>
                    <p>

                        At ForunPoint, we're committed to staying at the forefront of innovation in the food delivery industry. Our Voice Command feature is just one example of how we're utilising technology to enhance the customer experience and make ordering food more convenient than ever before. Join us as we embrace the future of food ordering with ForunPoint's Voice Command feature.


                    </p>
                    <h2> Conclusion</h2>
                    <p>


                        This innovative feature isn't just a gimmick – it's a game-changer. Whether you're a multitasking marvel, a commuting connoisseur, or a couch potato extraordinaire, our Voice Command feature puts the power of ordering at your fingertips – or rather, at the sound of your voice. Experience the convenience of hands-free ordering with ForunPoint today.  Simply download the ForunPoint app and start ordering your favourite meals with just the sound of your voice. Your taste buds – and your Netflix queue – will thank you.



                    </p>
                </div>
            </div>


            <Footer />

        </div>
    )
}

export default firstpage
