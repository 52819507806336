import React from 'react'
import './divider.css'
import forunpointLabel from '../../assets/images/forunpoint-label.png'

function divider() {
  return (
    <div className='divider'>
      <img src={forunpointLabel} className='forunpoint-label' />
    </div>
  )
}

export default divider
