// import React, { useState, useEffect } from 'react';
// import './wallet.css'
// import Navbar from '../Navbar/Navbar.js'
// import icon1 from '../../assets/images/Group 1.png'
// import icon2 from '../../assets/images/Group 1(1).png'
// import { FaArrowUp } from "react-icons/fa6";
// import { FaArrowDown } from "react-icons/fa6";
// import { getMe, getBalance, deposit, makeTransaction } from '../../shared/apis.js';


// function Wallet() {

//     const init = async () => {
//         let user = await getMe();
//         let userId = user.data.user?._id
//         let getbalance = await getBalance(userId);
//         console.log(getbalance)

//     }
//     useEffect(() => {
//         init();
//     }, []);

//     return (
//         <div>
//             <Navbar />
//             <div className="wallet-section">
//                 <h2 className="wallet-heading">Wallet History</h2>
//                 <div className="dep-sec">
//                     <p className='wallet-subheadings'>Deposit <FaArrowDown className='up-arrow-deposit' /></p>
//                     <div className='vertical-line'></div>
//                     <p className='wallet-subheadings'>Withdrawal <FaArrowUp className='down-arrow-withdrawal' /></p>
//                 </div>
//                 <div className="money-sec">
//                     <div className='d-flex'>
//                         <img src={icon1} alt="" />
//                         <div className='my-2'>
//                             <p className="moneyname">a5li-sb01</p>
//                             <p className="moneytime">06:45 pm</p>
//                         </div>
//                     </div>
//                     <p className='money-value'>-100</p>
//                 </div>
//                 <div className="money-sec">
//                     <div className='d-flex'>
//                         <img src={icon2} alt="" />
//                         <div className='my-2'>
//                             <p className="moneyname">a5li-sb01</p>
//                             <p className="moneytime">06:45 pm</p>
//                         </div>
//                     </div>
//                     <p className='money-value2'>+121</p>
//                 </div>
//             </div>
//         </div>
//     )
// }


// export default Wallet
import React, { useState, useEffect } from 'react';
import './wallet.css';
import Navbar from '../Navbar/Navbar.js';
import icon1 from '../../assets/images/Group 1.png';
import icon2 from '../../assets/images/Group 1(1).png';
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { getMe, getBalance } from '../../shared/apis.js';
import Footer from '../Footer/footer.jsx';
import { ConvertPKRtoUSD } from '../../App';


function Wallet() {
    const [transactions, setTransactions] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);

    const init = async () => {
        let user = await getMe();
        let userId = user.data.user?._id;
        let getbalance = await getBalance(userId);

        if (getbalance && getbalance.data && getbalance.data.Wallet) {
            setTransactions(getbalance.data.Wallet.transactions);
            setWalletBalance(getbalance.data.Wallet.balance)   
        }
    }

    useEffect(() => {
        init();
    }, []);

    const formatDate = (date) => {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    }

    // return (
    //     <div>
    //         <Navbar />
    //         <div className="wallet-section">
    //             <h2 className="wallet-heading">Wallet History</h2>
    //             <div className="dep-sec">
    //                 <p className='wallet-subheadings'>Deposit <FaArrowDown className='up-arrow-deposit' /></p>
    //                 <div className='vertical-line'></div>
    //                 <p className='wallet-subheadings'>Withdrawal <FaArrowUp className='down-arrow-withdrawal' /></p>
    //             </div>

    //             {transactions.map(transaction => (
    //                 <div key={transaction._id} className="money-sec">
    //                     <div className='d-flex'>
    //                         <img src={transaction.type === 'deposit' ? icon2 : icon1} alt="" />
    //                         <div className='my-2'>
    //                             <p className="moneyname">
    //                                 {transaction.type === 'deposit' ? 'Deposit' : 'Withdrawal'}
    //                             </p>
    //                             <p className="moneytime">{formatDate(transaction.date)}</p>
    //                         </div>
    //                     </div>
    //                     {/* <p className={transaction.type === 'deposit' ? 'money-value2' : 'money-value'}>
    //                             {transaction.type === 'deposit' ? '+' : '-'}{transaction.amount}
    //                         </p> */}
    //                     <p className={transaction.type === 'deposit' ? 'money-value2' : 'money-value'}>
    //                         {ConvertPKRtoUSD(`${transaction.type === 'deposit' ? '+' : '-'}${transaction.amount}`)}
    //                     </p>

    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );
    return (
        <div>
            <Navbar />
            <div className="wallet-section">
                <h2 className="wallet-heading">Wallet History</h2>
                <h2 className="wallet-heading current-balance">Current Wallet Balance: <span className='balance-amount'>{ConvertPKRtoUSD(walletBalance)}</span></h2>

                <div className="dep-sec">
                    <p className='wallet-subheadings'>Deposit <FaArrowDown className='up-arrow-deposit' /></p>
                    <div className='vertical-line'></div>
                    <p className='wallet-subheadings'>Withdrawal <FaArrowUp className='down-arrow-withdrawal' /></p>
                </div>

                {transactions.length === 0 ? (
                    <p className="no-transactions">No transactions have been made yet.</p>
                ) : (
                    transactions.map(transaction => (
                        <div key={transaction._id} className="money-sec">
                            <div className='d-flex'>
                                <img src={transaction.type === 'deposit' ? icon2 : icon1} alt="" />
                                <div className='my-2'>
                                    <p className="moneyname">
                                        {transaction.type === 'deposit' ? 'Deposit' : 'Withdrawal'}
                                    </p>
                                    <p className="moneytime">{formatDate(transaction.date)}</p>
                                </div>
                            </div>
                            <p className={transaction.type === 'deposit' ? 'money-value2' : 'money-value'}>
                                {ConvertPKRtoUSD(`${transaction.type === 'deposit' ? '+' : '-'}${transaction.amount}`)}
                            </p>
                        </div>
                    ))
                )}
            </div>
            {/* <Footer /> */}
        </div>
    );

}

export default Wallet;
