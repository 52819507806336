import axios from 'axios';
import { API_URL } from '../shared/constants/config';
const instance = axios.create({ 
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  },});
  if (localStorage.getItem('token')){
    instance.interceptors.request.use(function (config) {
      config.headers.Authorization =`Bearer ${localStorage.getItem('token')?.replace(/"/g, '')}`;
      return config;
    });
  }


export default instance