import React, { useEffect } from "react";

const Facebook = () => {
  useEffect(() => {
    // Immediately Invoked Function Expression (IIFE) to load Facebook Pixel script
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    // Define fbq as a global function
    window.fbq = window.fbq || function () {
      if (!window.fbq.callMethod) {
        window.fbq.callMethod = function () {
          window.fbq.queue.push(arguments);
        };
      }
    };

    // Initialize Facebook Pixel
    window.fbq('init', '461194763121767');
    window.fbq('track', 'PageView');
  }, []);

  return null;
};

export default Facebook;

