import React, { useEffect } from 'react';

const PlatformRedirect = () => {
  useEffect(() => {
    const detectAndRedirect = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Redirect to Google Play for Android devices
      if (/android/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.whetcloud.de_vour_chef";
        return;
      }

      // Redirect to App Store for iOS devices
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = "https://apps.apple.com/us/app/forunpoint-partners/id1541482615";
        return;
      }

      // Redirect to a fallback or home page for non-Android/iOS devices
      window.location.href = "https://forunpoint.com.pk";
    };

    detectAndRedirect();
  }, []);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default PlatformRedirect;
