import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'react-filter-box/lib/react-filter-box.css';
import { IoIosStar } from "react-icons/io";
import { LuChefHat } from "react-icons/lu";
import { Range, getTrackBackground } from 'react-range';
import './filter-gifts.css';

const FilterGift = ({filterSearch}) => {
    const [showFilters, setShowFilters] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const [values, setValues] = useState([0, 100]);
    const [activeButton, setActiveButton] = useState(null);
    const [isBestRestaurantsDisabled, setBestRestaurantsDisabled] = useState(false);

    const handleButtonClick = (index) => {
        // Disable the "Best Restaurants" button (index 5)
        if (index === 5) {
            setBestRestaurantsDisabled(true);
            return;
        }

        setActiveButton(index);
    };


    return (
        <div className="filter-container">
            {isMobile ? (
                <>
                    {/* <button className="filter-button" onClick={toggleFilters}>
                        {showFilters ? 'Close Filters' : 'Open Filters'}
                    </button> */}

                    <img src="./images/filter-btn.png" onClick={toggleFilters} className='filter-button' />

                    {showFilters && (
                        <div className="popup-overlay" onClick={toggleFilters}>
                            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                                <div className="popup-section">
                                    <h2 className="section-title">Price</h2>
                                    <div className="price-range-slider-container">
                                        <Range
                                            step={1}
                                            min={0}
                                            max={100}
                                            values={values}
                                            onChange={(newValues) => setValues(newValues)}
                                            renderTrack={({ props, children }) => (
                                                <div
                                                    {...props}
                                                    className="range-track"
                                                    style={{
                                                        ...props.style,
                                                        backgroundColor: getTrackBackground({ values, colors: ['#ccc', '#548BF4', '#ccc'], min: 0, max: 100 }),
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            )}
                                            renderThumb={({ props }) => (

                                                <div {...props} className="range-thumb" style={{ ...props.style }} />
                                            )}
                                        />
                                        <div className="price-text">
                                            <span>${values[0]}</span>
                                            <span>${values[1]}</span>
                                        </div>
                                        <div className="price-line" />
                                    </div>
                                    <h2 className="section-title">Rating</h2>
                                    <div className="ratings">
                                        <button className={`rating-btn ${activeButton === 0 ? 'active' : ''}`} onClick={() => handleButtonClick(0)}>
                                            <IoIosStar className={`rating-star ${activeButton === 0 ? 'active' : ''}`} /> Rating 5
                                        </button>
                                        <button className={`rating-btn ${activeButton === 1 ? 'active' : ''}`} onClick={() => handleButtonClick(1)}>
                                            <IoIosStar className={`rating-star ${activeButton === 1 ? 'active' : ''}`} /> Rating 4.5
                                        </button>
                                        <button className={`rating-btn ${activeButton === 2 ? 'active' : ''}`} onClick={() => handleButtonClick(2)}>
                                            <IoIosStar className={`rating-star ${activeButton === 2 ? 'active' : ''}`} /> Rating 4
                                        </button>
                                        <button className={`rating-btn ${activeButton === 3 ? 'active' : ''}`} onClick={() => handleButtonClick(3)}>
                                            <IoIosStar className={`rating-star ${activeButton === 3 ? 'active' : ''}`} /> Rating 3.5
                                        </button>
                                        <button className={`rating-btn ${activeButton === 4 ? 'active' : ''}`} onClick={() => handleButtonClick(4)}>
                                            <IoIosStar className={`rating-star ${activeButton === 4 ? 'active' : ''}`} /> Rating 3
                                        </button>
                                        <button
                                            className={`rating-btn ${activeButton === 5 ? 'active' : ''} ${isBestRestaurantsDisabled ? 'disabled' : ''}`}
                                            onClick={() => handleButtonClick(5)}
                                            id='disabled-btn'
                                            disabled={isBestRestaurantsDisabled}
                                        >
                                            <LuChefHat
                                                className={`rating-star ${activeButton === 5 ? 'active' : ''} ${isBestRestaurantsDisabled ? 'disabled' : ''}`}
                                            />{' '}
                                            Best Restaurants
                                        </button>
                                    </div>
                                    <h2 className="section-title">Offers</h2>
                                    <div className="offers">
                                        <input type="checkbox" className='check-box' />&ensp;<span className='checkbox-text'  >Free Delivery</span>
                                        <br />
                                        <input type="checkbox" className='check-box' />&ensp;<span className='checkbox-text'>Coupons</span>
                                        <br />
                                        <input type="checkbox" className='check-box' />&ensp;<span className='checkbox-text'>Discount Deals</span>
                                    </div>
                                    <h2 className="section-title">Cuisines</h2>
                                    <div className="cuisines">
                                        <input type="checkbox" onClick={() => filterSearch('American')} className='check-box' />&ensp;<span className='checkbox-text'>American</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Burgers')} className='check-box' />&ensp;<span className='checkbox-text'>Burgers</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Chinese')} className='check-box' />&ensp;<span className='checkbox-text'>Chinese</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Desserts')} className='check-box' />&ensp;<span className='checkbox-text'>Desserts</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Healthy Food')} className='check-box' />&ensp;<span className='checkbox-text'>Healthy Food</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Thai')} className='check-box' />&ensp;<span className='checkbox-text'>Thai</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Nihari')} className='check-box' />&ensp;<span className='checkbox-text'>Nihari</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Pizza')} className='check-box' />&ensp;<span className='checkbox-text'>Pizza</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Seafood')} className='check-box' />&ensp;<span className='checkbox-text'>Seafood</span>
                                        <br />
                                        <input type="checkbox" onClick={() => filterSearch('Tea & Coffee')} className='check-box' />&ensp;<span className='checkbox-text'>Tea & Coffee</span>
                                        {/* <br />
                                        <input type="checkbox"  className='check-box' />&ensp;<span className='checkbox-text'>Vegetarian</span> */}
                                    </div>
                                    <h2 className="section-title">Sort By</h2>
                                    <div className="sort-by">
                                        <input type="checkbox" name="" id="" className='check-box' />&ensp;<span className='checkbox-text'>Relevance</span>
                                        <br />
                                        <input type="checkbox" name="" id="" className='check-box' />&ensp;<span className='checkbox-text'>Fast Delivery</span>
                                        <br />
                                        <input type="checkbox" name="" id="" className='check-box' />&ensp;<span className='checkbox-text'>Distance</span>
                                    </div>
                                </div>
                                <button className="popup-close-button" onClick={toggleFilters}>
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="filters">
                    <div className="filter-section">
                        <h2 className="section-title">Price</h2>
                        <div className="price-range-slider-container">
                            <Range
                                step={1}
                                min={0}
                                max={100}
                                values={values}
                                onChange={(newValues) => setValues(newValues)}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="range-track"
                                        style={{
                                            ...props.style,
                                            backgroundColor: getTrackBackground({ values, colors: ['#ccc', '#548BF4', '#ccc'], min: 0, max: 100 }),
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (

                                    <div {...props} className="range-thumb" style={{ ...props.style }} />
                                )}
                            />
                            <div className="price-text">
                                <span>${values[0]}</span>
                                <span>${values[1]}</span>
                            </div>
                            <div className="price-line" />
                        </div>

                        <h2 className="section-title">Rating</h2>
                        <div className="ratings">
                            <button className={`rating-btn ${activeButton === 0 ? 'active' : ''}`} onClick={() => handleButtonClick(0)}>
                                <IoIosStar className={`rating-star ${activeButton === 0 ? 'active' : ''}`} /> Rating 5
                            </button>
                            <button className={`rating-btn ${activeButton === 1 ? 'active' : ''}`} onClick={() => handleButtonClick(1)}>
                                <IoIosStar className={`rating-star ${activeButton === 1 ? 'active' : ''}`} /> Rating 4.5
                            </button>
                            <button className={`rating-btn ${activeButton === 2 ? 'active' : ''}`} onClick={() => handleButtonClick(2)}>
                                <IoIosStar className={`rating-star ${activeButton === 2 ? 'active' : ''}`} /> Rating 4
                            </button>
                            <button className={`rating-btn ${activeButton === 3 ? 'active' : ''}`} onClick={() => handleButtonClick(3)}>
                                <IoIosStar className={`rating-star ${activeButton === 3 ? 'active' : ''}`} /> Rating 3.5
                            </button>
                            <button className={`rating-btn ${activeButton === 4 ? 'active' : ''}`} onClick={() => handleButtonClick(4)}>
                                <IoIosStar className={`rating-star ${activeButton === 4 ? 'active' : ''}`} /> Rating 3
                            </button>
                            <button
                                className={`rating-btn ${activeButton === 5 ? 'active' : ''} ${isBestRestaurantsDisabled ? 'disabled' : ''}`}
                                onClick={() => handleButtonClick(5)}
                                id='disabled-btn'
                                disabled={isBestRestaurantsDisabled}
                            >
                                <LuChefHat
                                    className={`rating-star ${activeButton === 5 ? 'active' : ''} ${isBestRestaurantsDisabled ? 'disabled' : ''}`}
                                    id='disabled-hat'
                                />{' '}
                                Best Restaurants
                            </button>
                        </div>
                        <h2 className="section-title">Offers</h2>
                        <div className="offers">
                            <input
                                type="checkbox"
                                name=""
                                id=""
                                className={`check-box ${isBestRestaurantsDisabled ? 'disabled-checkbox' : ''}`}
                                disabled={isBestRestaurantsDisabled}
                            />&ensp;<span className='checkbox-text disabled-check'>Free Delivery</span>
                            <br />
                            <input type="checkbox" name="" id="" className='check-box' />&ensp;<span className='checkbox-text'>Coupons</span>
                            <br />
                            <input type="checkbox" name="" id="" className='check-box' />&ensp;<span className='checkbox-text'>Discount Deals</span>
                        </div>
                        <h2 className="section-title">Categories</h2>
                        <div className="cuisines">
                            <input type="checkbox" onClick={() => filterSearch('American')} className='check-box' />&ensp;<span className='checkbox-text'>Red Rose</span>
                            <br />
                            <input type="checkbox" onClick={() => filterSearch('Burger')} className='check-box' />&ensp;<span className='checkbox-text'>Godiva</span>
                            <br />
                            <input type="checkbox" onClick={() => filterSearch('Chinese')} className='check-box' />&ensp;<span className='checkbox-text'>Customized Bouquet</span>
                        </div>
                        <h2 className="section-title">Sort By</h2>
                        <div className="sort-by">
                            <input type="checkbox" name="" id="" className='check-box' />&ensp;<span className='checkbox-text'>Relevance</span>
                            <br />
                            <input
                                type="checkbox"
                                name=""
                                id=""
                                className={`check-box ${isBestRestaurantsDisabled ? 'disabled-checkbox' : ''}`}
                                disabled={isBestRestaurantsDisabled}
                            />&ensp;<span className='checkbox-text disabled-check'>Fast Delivery</span>
                            <br />
                            <input
                                type="checkbox"
                                name=""
                                id=""
                                className={`check-box ${isBestRestaurantsDisabled ? 'disabled-checkbox' : ''}`}
                                disabled={isBestRestaurantsDisabled}
                            />&ensp;<span className='checkbox-text disabled-check'>Distance</span>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default FilterGift;
