import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import React, { useState } from "react";
import "./driver.css";
import DatePicker from "react-datepicker";
import { joinUs } from "../../shared/apis";
import "react-datepicker/dist/react-datepicker.css";

function DriverJoinus() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    startDate: new Date(),
    information: "",
    carYear: "",
    carModel: "",
    licenseState: ""
  });
  const [error, setError] = useState("");
  const [deliveryexperinece, setdeliveryexperinece] = useState("");
  const [jobtype, setjobtype]=useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, startDate: date });
  };

  const changeDropDown = (e) => {
    setdeliveryexperinece(e.target.value);
  };
  const changeDropDown2 = (e) => {
    setjobtype(e.target.value);
  };

  const submit = async () => {
    let body = formData;
    body.workingHours = jobtype;
    body.deliveryExperience= deliveryexperinece;
    if (!body.name) {
      setError("Name is required.");
    } else if (!body.email) {
      setError("Email is required.");
    } else if (!body.phoneNumber) {
      setError("Phone number is required.");
    } else if (!body.address) {
      setError("Address is required.");
    } else if (!body.licenseState) {
      setError("Please write license state.");
    } else {
      setError('');
      await joinUs(body);
      window.location.reload();
    }
  };

  return (
    <>
    <Navbar/>
      <div className="joinus-section">
        <div className="joinus-form">
          <h1 className="title">Driver registration</h1>
          <div className="para">
            We kindly request you to complete the following form. Your responses
            will enable us to understand your needs and preferences effectively.
          </div>
          <div className="form-container-driver-join">
            <form>
              <label htmlFor="name">Name:</label>
              <br />
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="John Doe"
              />
              <br />
              <label htmlFor="email">Email:</label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="johndoe@mail.com"
              />
              <br />
              <label htmlFor="phone">Phone:</label>
              <br />
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="+100000000"
              />
              <br />
              <label htmlFor="address">Address:</label>
              <br />
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="TX, USA"
              />
              <br />
              <label htmlFor="licenseState">Valid Driver License (State):</label>
              <br />
              <input
                type="text"
                id="licenseState"
                name="licenseState"
                value={formData.licenseState}
                onChange={handleChange}
                placeholder="Texas"
              />
              <br />
              <label htmlFor="role">
                Experience:
              </label>
              <select className="multi-select" onChange={changeDropDown}>
              <option value="">Food Delivery Experience</option>
                <option value="none">none</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <br />
              <br/>
              <label htmlFor="carModel">Car brand and model:</label>
              <br />
              <input
                type="text"
                id="carModel"
                name="carModel"
                value={formData.carModel}
                onChange={handleChange}
                placeholder="Audi RS7"
              />
              <br /><label htmlFor="carYear">Year:</label>
              <br />
              <input
                type="text"
                id="carYear"
                name="carYear"
                value={formData.carYear}
                onChange={handleChange}
                placeholder="2023"
              />
              <br />

              <select className="multi-select" onChange={changeDropDown2}>
              <option value="">Job type:</option>
                <option value="Full_time">Full time</option>
                <option value="Part_time">Part time</option>
              
              </select>
            
              <br />
              <br/>
              <label htmlFor="dateTime">
              How early you can start:
              </label>
              <br />
              <DatePicker
                selected={formData.startDate}
                onChange={handleDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholderText="Pick a Date and Time"
              />
              <br />
             
           

              <label htmlFor="comment">
                Any additional information you would like to share?
              </label>
              <br />

              <textarea
                id="information"
                name="information"
                value={formData.comment}
                onChange={handleChange}
                placeholder="Information"
              />
              <br />
            </form>
            <div class="center-container">
              <button className="join-form-btn" onClick={() => submit()}>
                Submit
              </button>
            </div>
            <div className="error-text">{error}</div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default DriverJoinus;
