import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import { IoIosStar } from "react-icons/io";
import Spinner from 'react-bootstrap/Spinner';
import './favourites.css';

function Favourites() {
    const [slidesToShow, setSlidesToShow] = useState(3);
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [button, setButtonActive] = useState(true);
    const sliderRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);

    const detectMaxScroll = async () => {
        const scrollableDiv = sliderRef.current;
        const currentScroll = scrollableDiv.scrollTop;
        const maxScroll = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
        if (currentScroll === maxScroll) {
            setOffset(offset + 30);
        }
    };

    const init = async () => {
        setIsLoading(true);
        setIsLoading(false);
    }

    useEffect(() => {
        init();
        const handleScroll = (event) => {
            if (sliderRef.current && sliderRef.current.contains(event.target)) {
                sliderRef.current.scrollTop += event.deltaY;
            }
        };
        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='favourites-page'>
            <Navbar />
            <div className='favourites-content'>
                <h2 className='favourites-heading'>Your Favourite Restaurants</h2>
                <hr className='separator' />
                <div className="favourites-section">
                    <div className="vertical-slider" ref={sliderRef} slidesToShow={slidesToShow} onScroll={detectMaxScroll}>
                        {isLoading &&
                            <div className="slider-content">
                                <div className='mt-5' style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" variant="danger" />
                                </div>
                            </div>
                        }
                        {!isLoading &&
                            <div className="slider-content">
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title fav-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 km</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount fav-rating">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="results-card">
                                    <div className="image-container">
                                        <img src='./images/deal2.png' className="result-card-img" alt="..." />
                                    </div>
                                    <div className="results-card-body">
                                        <div className="results-card-details">
                                            <p className='rest-title'>Pizza Lovers</p>
                                            <p className='results-mile'>2.5 mile</p>
                                        </div>
                                        <div className="results-rating-sec ">
                                            <p className="rating-amount">(4.5) <IoIosStar className='results-star' /></p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Favourites
