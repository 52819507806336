import React, { useState, useEffect, useRef } from 'react';
import './chatsupport.css';
import io from 'socket.io-client';
import { IoClose } from "react-icons/io5";
import { MdDoneAll } from "react-icons/md";
import chatIcon from '../../assets/images/chat-ico.png';
import sendMessageSound from '../../assets/images/sending.mp3';
import receiveMessageSound from '../../assets/images/receiving.mp3';

const socket = io('https://forunpoint-messenger-32c70a9f9d0c.herokuapp.com/');

const ChatButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const chatRef = useRef(null);

    const sendAudioRef = useRef(null);
    const receiveAudioRef = useRef(null);

    // useEffect(() => {
    //     const staticReceivedMessages = [
    //         { sender: 'Friend', text: 'Hello!', timestamp: new Date().toISOString(), status: 'seen' },
    //         { sender: 'Friend', text: 'How are you?', timestamp: new Date().toISOString(), status: 'seen' },
    //         { sender: 'Friend', text: 'This is a static message.', timestamp: new Date().toISOString(), status: 'seen' },
    //     ];

    //     setMessages(staticReceivedMessages);
    // }, []);

    useEffect(() => {
        socket.on('chat message', (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
            playReceiveSound();
        });

        return () => {
            socket.off('chat message');
        };
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const sendMessage = () => {
        if (message.trim() !== '') {
            const newMessage = {
                sender: 'Me',
                text: message,
                timestamp: new Date().toISOString(),
                status: 'sent'
            };

            setMessages(prevMessages => [...prevMessages, newMessage]);
            socket.emit('chat message', newMessage);
            setMessage('');
            playSendSound();

            // setTimeout(() => {
            //     const receivedMessage = {
            //         sender: 'Friend',
            //         text: 'Received your message!',
            //         timestamp: new Date().toISOString()
            //     };
            //     setMessages(prevMessages => [...prevMessages, receivedMessage]);
            //     playReceiveSound();
            // }, 1500); 
        }
    };

    const updateMessageStatus = (timestamp, status) => {
        setMessages(prevMessages => prevMessages.map(msg =>
            msg.timestamp === timestamp ? { ...msg, status } : msg
        ));
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
        }
    };

    const playSendSound = () => {
        if (sendAudioRef.current) {
            sendAudioRef.current.play();
        }
    };

    const playReceiveSound = () => {
        if (receiveAudioRef.current) {
            receiveAudioRef.current.play();
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    };    

    return (
        <div className="chat-button-container">
            {isOpen && (
                <div className="chat-section" ref={chatRef}>
                    <div className="chat-header">
                        <p className='header-txt'>Chat With Rider <span className='close-ico' onClick={handleClose}><IoClose /></span></p>
                    </div>
                    <div className="msg-section">

                        <div className="messages-container">
                            {messages.map((msg, index) => (
                                <div key={index} className={`message-container ${msg.sender === 'Me' ? 'sent' : 'received'}`}>
                                    <div className={`message-bubble ${msg.sender === 'Me' ? 'sent-bubble' : 'received-bubble'}`}>
                                        <div className="message-text">{msg.text}</div>
                                        <div className="message-timestamp">{formatTimestamp(msg.timestamp)}</div>
                                        {msg.sender === 'Me' && (
                                            <div className={`message-status ${msg.status === 'seen' ? 'seen' : 'sent'}`}>
                                                <MdDoneAll />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <textarea
                        type="text"
                        placeholder="Type your message..."
                        value={message}
                        onChange={handleMessageChange}
                        onKeyDown={handleKeyDown}
                    />
                    <img src="./images/send-btn.png" onClick={sendMessage} className='send-btn' alt="Send Message Button" />
                </div>
            )}
            <img src={chatIcon} className="chat-button" alt="Chat" onClick={handleToggle} />

            <audio ref={sendAudioRef} src={sendMessageSound} />
            <audio ref={receiveAudioRef} src={receiveMessageSound} />
        </div>
    );
};

export default ChatButton;
