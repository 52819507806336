import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import Accordion from "react-bootstrap/Accordion";
import "./faqs.css";

function Faqs() {
  return (
    <div>
      <Navbar />
      <div className="faq-section">
        <div className="section-faqs mt-5 pb-5" id="faqs">
          <div className="text-center">
            <div className="faqs-title">FAQ's</div>
            <div className="faqs-subtitle">
              Most frequent questions and answers
            </div>
          </div>
          <Accordion className="section-accordion mt-3"></Accordion>
          <Accordion className="section-accordion mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
              HOW DOES THE ORDER PROCESS WORK?
              </Accordion.Header>
              <Accordion.Body>
              Users and restaurants communicate via platform to confirm the order. Once the order is placed, fresh food will be available for pick up or delivery.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
              WHAT IS THE CANCELLATION POLICY?
              </Accordion.Header>
              <Accordion.Body>Users or restaurants can cancel via platform prior to the preparation of the order. Once order is in progress cancellation of order is not possible.</Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="section-accordion mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
              CAN I SEND FLOWERS, GIFTS, AND ITEMS FROM MY FAVOURITE RESTAURANTS?
              </Accordion.Header>
              <Accordion.Body>
              Yes, you can do this by scheduling a delivery.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                WHAT IS THE CANCELLATION POLICY?
              </Accordion.Header>
              <Accordion.Body>
                User or Restaurent can cancel via platform prior placing order.
                Once order is in progress cancelling order not possible
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="section-accordion mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>HOW CAN I CONTACT THE DRIVER?</Accordion.Header>
              <Accordion.Body>
                You can do so through the chat option.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                IF I HAVE TECHNICAL ISSUES HOW TO COMMUNICATE?
              </Accordion.Header>
              <Accordion.Body>
                Contact us via email at info@forunpoint.com
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="section-accordion mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                HOW RESTAURENTS CAN ACCESS THEIR DASHBOARD?
              </Accordion.Header>
              <Accordion.Body>
                Simply go to{" "}
                <a
                  href="https://console.forunpoint.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Restaurant Dashboard
                </a>{" "}
                and login with your credentials
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
              WHAT ABOUT OTHER LOCAL LAWS AND REGULATIONS?
              </Accordion.Header>
              <Accordion.Body>
              It’s imperative to understand that each state, county, and city has its own rules and regulations that may impact your availability. For example, your services may be subject to laws that govern running a business. You may need to register with the respective authorities, get a business licence, and/or obtain a permit before you can make your services available for hire. It's important that you check out the laws applicable to you and make sure you’re in compliance with them. By opening an account with ForunPoint, you’re agreeing that you are and will remain in compliance with such laws and regulations.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="section-accordion mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                HOW QUICKLY CAN RESTAURANT DISHES BE ADDED TO THE PLATFORM?
              </Accordion.Header>
              <Accordion.Body>
                All dishes will be updated in real-time. We prefer that dishes
                be added using the restaurant dashboard.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                WHAT ABOUT OTHER LOCAL LAWS AND REGULATIONS?
              </Accordion.Header>
              <Accordion.Body>
                It’s imperative to understand that each state, county, and city
                has its own rules and regulations that may impact your
                availability. For example, your services may be subject to laws
                that govern running a business. You may need to register with
                the respective authorities, get a business license, and/or
                obtain a permit before you can make your services available for
                hire. it’s important that you check out the laws applicable to
                you and make sure you’re in compliance with them. By opening an
                account with ForunPoint, you’re agreeing that you are and will
                remain in compliance with such laws and regulations.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="section-accordion mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
              IN WHICH AREAS IS FORUNPOINT AVAILABLE?
              </Accordion.Header>
              <Accordion.Body>
              Currently we are operating in areas of Lahore like, DHA, Model Town, Gulberg, and Johar Town. We will be expanding to more areas soon.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                WHAT IS RECOMMENDED OPTION TO ADD DISH OR MENU?
              </Accordion.Header>
              <Accordion.Body>
                Restaurant should use their dashboard to manage their menus and
                orders.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faqs;
