// import "./about-section.css";

// const AboutSection = () => {
//   return (
//     <>
//       <div className="container about-section" id="about">
//         <div className="row">
//           <div className="col-md-6 col-lg-5">
//             <div className="about-card">
//               <div className="card-body">
//                 <img
//                   className="about-section-img"
//                   src="./images/about01.png"
//                   alt=""
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 col-lg-6">
//             {/* <!-- Column 2 content --> */}
//             <div className="about-card-content">
//               <div className="card-body">
//                 <h2 className="about-section-heading">About ForunPoint</h2>
//                 <img
//                   src="./images/about-underline.png"
//                   alt=""
//                   className="underline"
//                 />
//                 &nbsp;
//                 <img
//                   src="./images/about-underline-s.png"
//                   alt=""
//                   className="underline"
//                 />
//                 &nbsp;
//                 <img
//                   src="./images/about-underline-xs.png"
//                   alt=""
//                   className="underline"
//                 />
//                 <p className="about-section-content">
//                 Welcome to ForunPoint, your gateway to exceptional dining experiences. Our platform is dedicated to bringing mouth-watering dishes from top restaurants right to your doorstep. With a focus on accessibility and convenience, ForunPoint offers a diverse range of culinary delights tailored to individual tastes.
//                   <br />
//                   <br /> What sets ForunPoint apart is our unparalleled search capability, allowing users to explore hundreds of unique and popular dishes based on country or dish reference. Whether you're craving authentic Mexican cuisine, comforting Italian pasta, or exotic Thai flavours, ForunPoint delivers.
//                   <br /> <br /> Experience the convenience of ordering delicious meals from your favourite restaurants directly from your mobile device with ForunPoint's online food ordering platform. With real-time order tracking and a seamless user experience, ForunPoint is here to satisfy your cravings and elevate your dining experience. Join us today and discover a world of culinary delights at your fingertips.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AboutSection;


import "./about-section.css";

const AboutSection = () => {
  return (
    <>
      <div className="about-section" id="about">
        <div className="row">
          <div className="col-md-6 col-lg-7 order-md-2">
            <div className="about-card-content">
              <div className="card-body">
                <h1 className="aboutus-heading">About Us</h1>
                <h2 className="about-section-heading">About ForunPoint</h2>
                <div className="underline-sec">
                  <div className="underline1"></div>
                  &nbsp;
                  <div className="underline2"></div>
                  &nbsp;
                  <div className="underline3"></div>
                </div>
                <p className="about-section-content">
                  ForunPoint is a US-based company that originated in Frisco, Texas. At ForunPoint, our mission is to completely revolutionize the delivery experience and bring happiness directly to our customer’s doorstep. We envision a future where anyone can seamlessly order food, flowers, and gifts for themselves or their loved ones. At ForunPoint, we value innovation, convenience, and ingenuity above all else. We inculcate these values in the development of our services to drive our business to unparalleled heights. We are excited to announce user-centric innovations like voice ordering, allowing users to send gifts from anyplace to their loved ones residing anywhere we provide our delivery services to, along with our video memo feature that cultivates the emotional connection between your family and friends.
                  <br />
                  <br /> ForunPoint closely collaborates with our esteemed restaurant partners and provides them with the opportunity to broaden their audience and boost revenue. We offer restaurant owners numerous perks and benefits, like better incentives, low commission rates, device-less integration, and SaaS solutions. These perks provide our restaurant partners with a competitive advantage in the food industry.
                  <br /> <br /> Our team of dedicated members works tirelessly to connect users with our amazing restaurant partners. Our company caters to foodies, restaurant owners, and riders and is currently operational in Lahore, Pakistan’s food capital, with plans to expand in the near future. So whether you're a restaurant owner, a prospective buyer, or a rider, ForunPoint has you completely covered.
                  <br />
                  ForunPoint – Your Desire, One Tap Away!
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 order-md-1">
            <div className="about-card">
              <div className="card-body img-body">
                <img
                  className="about-section-img"
                  src="./images/about01.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
