import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import CookieConsent from "../../cookies-consent/cookies-consent";
import "./LandingHome.css";
import salad from '../../../assets/images/salad.png'
import landingImg from '../../../assets/images/landing-img.png'
import forunpointText from '../../../assets/images/forunpoint-text.png'
import rider from '../../../assets/images/rider.png'
import { useNavigate } from 'react-router-dom';
import Lahore from '../../../assets/images/lhr.png'
import Karachi from '../../../assets/images/khr.png'
import Islamabd from '../../../assets/images/isl.png'
import Rawalpindi from '../../../assets/images/rwp.png'
import { IoIosCheckmarkCircle } from "react-icons/io";
import PK from '../../../assets/images/Flag.png'
import US from '../../../assets/images/us.png'
import Dallas from '../../../assets/images/dallas.png'



const LandingHome = () => {
  const navigate = useNavigate();
  const handleCityClick = (city) => {
    navigate(`/restaurants/city/${city}`);

  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    flag: null,
  });

  const countries = [
    { name: "Pakistan", flag: PK },
    { name: "United States", flag: US },
  ];

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };
  return (
    <div className="landing-home-container">
      <Navbar />
      <div className="landing-page">
        <div className="image-sec"></div>
        <div className="landing-content">
          <img src={forunpointText} alt="Forunpoint Text" className="forunpoint-text" />
          <h1 className="landing-heading">Your Desire One Tap Away!</h1>
          {/* <p className="landing-description">Chutki Bajate Forun Hazir!</p> */}
          <p className="landing-description">FREE DELIVERY ON FOOD AND GIFTS!</p>
          <h1 className="landing-headings">Send food and gifts together to the following cities.</h1>
          <div className="country-dropdown-container">
            <div className="country-input" onClick={toggleDropdown}>
              {selectedCountry.flag && (
                <img src={selectedCountry.flag} alt={`${selectedCountry.name} Flag`} className="flag" />
              )}
              <span>{selectedCountry.name || "Select a Country"}</span>
            </div>
            {showDropdown && (
              <div className="country-dropdown">
                {countries.map((country) => (
                  <div
                    key={country.name}
                    className="dropdown-option"
                    onClick={() => selectCountry(country)}
                  >
                    <img src={country.flag} alt={`${country.name} Flag`} className="flag" />
                    <span>{country.name}</span>
                    {selectedCountry.name === country.name && (
                      <span className="checkmark"><IoIosCheckmarkCircle /></span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <img src={salad} className="salad-img" alt="Salad" />
        <img src={landingImg} className="dish-img" alt="Dish" />
        <img src={rider} className="rider-img" alt="Rider" />
        <div className="row landingcard2">
          <div className="col-md-6 col-lg-5">
            <div className="landing-card">
              <div className="card-body">
                <div className="input-group md-form form-sm pl-0 form-2 d-flex justify-content-center">
                  {/* <div class="button-container">
                    <button class="city-button active" onClick={() => handleCityClick('Lahore')}><img src={Lahore} alt="White Icon" className="icon-small" /> Lahore</button>
                    <button class="city-button" onClick={() => handleCityClick('Karachi')} ><img src={Karachi} alt="White Icon" className="icon-small" /> Karachi</button>
                    <button class="city-button" onClick={() => handleCityClick('Islamabad')} ><img src={Islamabd} alt="White Icon" className="icon-small" /> Islamabad</button>
                    <button class="city-button" onClick={() => handleCityClick('Rawalpindi')} ><img src={Rawalpindi} alt="White Icon" className="icon-small" />Rawalpindi</button>
                  </div> */}
                  <div className="button-container">
                    {selectedCountry.name === "Pakistan" && (
                      <>
                        <button className="city-button" onClick={() => handleCityClick('Lahore')}>
                          <img src={Lahore} alt="Lahore Icon" className="icon-small" /> Lahore
                        </button>
                        <button className="city-button" onClick={() => handleCityClick('Karachi')}>
                          <img src={Karachi} alt="Karachi Icon" className="icon-small" /> Karachi
                        </button>
                        <button className="city-button" onClick={() => handleCityClick('Islamabad')}>
                          <img src={Islamabd} alt="Islamabad Icon" className="icon-small" /> Islamabad
                        </button>
                        <button className="city-button" onClick={() => handleCityClick('Rawalpindi')}>
                          <img src={Rawalpindi} alt="Rawalpindi Icon" className="icon-small" /> Rawalpindi
                        </button>
                      </>
                    )}
                    {selectedCountry.name === "United States" && (
                      <button className="city-button dallas-btn" onClick={() => handleCityClick('Dallas')}>
                        <img src={Dallas} alt="Dallas Icon" className="icon-small" /> Dallas
                      </button>
                    )}
                  </div>



                  <div className="input-group-append"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
    </div>
  );
};

export default LandingHome;
