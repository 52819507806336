import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import FilterGift from '../filters-gifts/filter-gifts';
import { nearByResturants, topRestaurants, featureDeals, autoCompleteSearch, keyworkSearch } from '../../shared/apis';
import './gifts.css';
import { settings, settings2, settings3 } from './settings';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosStar, IoIosAdd } from "react-icons/io";


const Gifts = () => {
    const sliderRef = useRef(null);
    const [resturants, setresturants] = useState([]);
    const [topResturants, setTopResturants] = useState([]);
    const [featuredDeals, setfeaturedDeals] = useState([]);
    const [likedRestaurants, setLikedRestaurants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [coordinates] = useState([33.148150, -96.763130]);
    const [coordinates] = useState([31.3728262, 74.1592163]);
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();
    const set = settings;
    const set2 = settings2;
    const set3 = settings3;
    const [slidesToShow, setSlidesToShow] = useState(3);
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchType, setSearchType] = useState('RESTAURANT');
    const divRef = useRef(null);
    const [searchResult, setSearchResults] = useState([]);
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);

    const handleRestaurantClick = () => {
        setSearchType('RESTAURANT');
    };

    // const handleSearchFocus = () => {
    //     setIsSearchFocused(true);
    // };

    const handleSearchFocus = () => {
        setIsSearchFocused(true);
        setSearchType('RESTAURANT'); // Set the default search type to 'RESTAURANT'
    };

    const init = async () => {
        setIsLoading(true);
        scrollToTop();
        let topResturant = await topRestaurants(coordinates[0], coordinates[1]);
        let user = await nearByResturants('CHEF', coordinates[0], coordinates[1], offset, 30);
        let featuresDeals = await featureDeals(coordinates[0], coordinates[1]);
        setfeaturedDeals([...featuresDeals.data.coupons, ...featuresDeals.data.offers]);
        setresturants(user.data.users.filter(user => user.coverPhoto !== ''));
        setTopResturants(topResturant.data.restaurants.filter(user => user.coverPhoto !== ''));
        setIsLoading(false);
    }

    useEffect(() => {
        init();
        const handleScroll = (event) => {
            if (sliderRef.current && sliderRef.current.contains(event.target)) {
                sliderRef.current.scrollTop += event.deltaY;
            }
        };
        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        init();
        const handleResize = () => {
            if (sliderRef.current) {
                const containerWidth = sliderRef.current.clientWidth;
                const newSlidesToShow = Math.floor(containerWidth / 300);
                setSlidesToShow(newSlidesToShow);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line 
    }, []);

    const haversine = (lat1, lon1, lat2, lon2) => {
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        let dlat = lat2 - lat1;
        let dlon = lon2 - lon1;

        let a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const R = 6371;
        let distance = R * c;
        return distance.toFixed(2);
    }

    const toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }

    const handleHeartClick = (userId) => {
        const updatedLikedRestaurants = likedRestaurants.includes(userId)
            ? likedRestaurants.filter(id => id !== userId)
            : [...likedRestaurants, userId];
        setLikedRestaurants(updatedLikedRestaurants);
    };

    const detectMaxScroll = async () => {
        const scrollableDiv = sliderRef.current;
        const currentScroll = scrollableDiv.scrollTop;
        const maxScroll = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
        if (currentScroll === maxScroll) {
            setOffset(offset + 30);
            let user = await nearByResturants('CHEF', coordinates[0], coordinates[1], offset, 30);
            let usersWithCover = user.data.users.filter(user => user.coverPhoto !== '');
            setresturants((resturants) => [...resturants, ...usersWithCover]);
        }
    };

    const goToRestaurantHome = (id) => {
        navigate(`/restaurant-home/${id}`);
    }

    const searchAutoComplete = async (input) => {
        let body = {
            dish: input,
            type: searchType,
            lat: coordinates[0],
            lng: coordinates[1],
        }
        if (input.length > 0) {
            setIsSearchFocused(true);
            let suggestions = await autoCompleteSearch(body);
            setSuggestions(suggestions.keywords);
        } else {
            setIsSearchFocused(false);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleOutsideClick = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsSearchFocused(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            handleOutsideClick(event);
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const searchKeyword = async (keyword) => {
        let body = {};
        let results = [];
        body = {
            chef: keyword,
            lat: coordinates[0],
            lng: coordinates[1],
        }
        results = await keyworkSearch(body);
        setSearchResults(results.data.users)
        setIsSearchFocused(false);
        setIsSearchTriggered(true);
    }

    const filterSearch = async (keyword) => {
        let body = {};
        let results = [];
        setSearchType('RESTAURANTS')
        body = {
            chef: keyword,
            lat: coordinates[0],
            lng: coordinates[1],
        }
        results = await keyworkSearch(body);
        window.scrollTo(0, 0);
        setSearchResults(results.data.users)
        setIsSearchFocused(false);
        setIsSearchTriggered(true);
    }

    const goBack = () => {
        setSearchResults([]);
        init();
        setIsSearchTriggered(false);
        setIsSearchFocused(false);
    }

    return (
        <>
            <Navbar />
            <div className='restaurants-page'>
                <div class="rest-search">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-md-8">
                            <div class="rest-search-bar">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Restaurant, Foods, etc"
                                    onFocus={handleSearchFocus}
                                    onChange={(e) => {
                                        searchAutoComplete(e.target.value);
                                    }}
                                />
                                <a>
                                    <button class="toggle-btn btn-primary">
                                        Search
                                    </button>
                                </a>
                            </div>
                            {isSearchFocused && (
                                <div ref={divRef} className="search-section">
                                    {/* <div className="toggle-btns">
                                        <button
                                            className={`btn-primary nav-item restaurant-btn`}
                                            id='restaurant-btn'
                                            onMouseDown={handleRestaurantClick}
                                        >
                                            Restaurants
                                        </button>
                                    </div> */}
                                    {suggestions.map((keyword) => (
                                        <p style={{ cursor: 'pointer' }} onClick={() => searchKeyword(keyword)} >{keyword}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isSearchTriggered && <div className="rest-results-content">
                    <h3 className='rest-results-title'><a href="/restaurants"><FaArrowLeft onClick={() => goBack()} className='left-arrow' /></a> {searchResult?.length} Restaurants Found</h3>
                    <hr />
                    <div className="vertical-slider" ref={sliderRef} slidesToShow={slidesToShow} onScroll={detectMaxScroll}>
                        {isLoading &&
                            <div className="slider-content">
                                <div className='mt-5' style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" variant="danger" />
                                </div>
                            </div>
                        }
                        {!isLoading &&
                            <div className="slider-content">
                                {searchResult.map((user) => (
                                    <div onClick={() => goToRestaurantHome(user._id)} className="results-card">
                                        <div className="image-container">
                                            <img src={user.coverPhoto} className="result-card-img" alt="..." />
                                        </div>
                                        <div className="results-card-body">
                                            <div className="results-card-details">
                                                <p className='rest-title'>{user?.restaurantName?.substring(0, 9)}</p>
                                                <p className='results-mile'>{haversine(coordinates[0], coordinates[1], user?.location?.coordinates[1], user?.location?.coordinates[0])} km</p>
                                            </div>
                                            <div className="results-rating-sec ">
                                                <p className="rating-amount">({user.rating}) <IoIosStar className='results-star' /></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                }
                {!isSearchTriggered &&
                    <div className="carousel-container">

                        <hr />
                        <div className="deal-section row">
                            <div className="deal-details">
                                <div className="card-body featured-body">
                                    <div className="body-wrapper">
                                        <h5 className="card-title">Featured</h5>
                                        <p className="card-text">Deals</p>
                                    </div>
                                    <div className='deal-time'>00:00</div>
                                </div>
                            </div>
                            <div className='featur-dealS'>
                                {isLoading &&
                                    <div className='mt-5' style={{ textAlign: 'center' }}>
                                        <Spinner animation="border" variant="danger" />
                                    </div>
                                }
                                {!isLoading &&
                                    <Slider {...set3}>
                                        {featuredDeals.map((dish) => (
                                            <div key={dish._id}>
                                                <div className="deal-card">
                                                    <img src={dish?.picture} alt="Dish Picture" className="feature-img" />
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                }
                            </div>
                        </div>
                        <hr />
                        <FilterGift filterSearch={filterSearch} />
                        <div className="top-restaurants">


                            <h3 className='restaurant-heading'>Nearby Restaurants</h3>
                            <div className="vertical-slider" ref={sliderRef} slidesToShow={slidesToShow} onScroll={detectMaxScroll}>
                                {isLoading &&
                                    <div className="slider-content">
                                        <div className='mt-5' style={{ textAlign: 'center' }}>
                                            <Spinner animation="border" variant="danger" />
                                        </div>
                                    </div>
                                }
                                {!isLoading &&
                                    <div className="slider-content">
                                        {resturants.map((user) => (
                                            <div onClick={() => goToRestaurantHome(user._id)} key={user._id} className="nearby-card">
                                                <div className="plus-sign"><IoIosAdd className='plus-ico' /></div>

                                                <div className="image-container">
                                                    <img src={user?.coverPhoto} className="nearby-img" alt="..." />
                                                </div>
                                                <div className="nearby-card-body">
                                                    <div className='nearby-content'>
                                                        <p className='rest-title'>{user?.restaurantName?.substring(0, 10)}</p>
                                                        <p className='mile'>{haversine(33.148150, -96.763130, user?.location?.coordinates[1], user?.location?.coordinates[0])} km</p>
                                                    </div>
                                                    <div className="rating-section star-section">
                                                        <p className="rating-amount">({user?.rating}) <IoIosStar className='star' /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </>
    );
};

export default Gifts;